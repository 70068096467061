import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDietPlan: (mastuuid) => {
		return axios.get(`${API_ENDPOINTS.GET_DIET_PLAN_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getAllPlanName: (mastuuid) => {
		return axios.get(`${API_ENDPOINTS.GET_ENTIRE_PLAN_NAME_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getSinglePlanName: (planUuid, mastuuid, params) => {
		return axios.get(`${API_ENDPOINTS.GET_SINGLE_PLAN_NAME_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, planUuid } },
			params: { ...params },
		})
	},
	getClientPatientDietPlan: (mastuuid) => {
		return axios.get(`${API_ENDPOINTS.GET_CLIENT_DIET_PLAN_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	postDietPlan: (mastuuid, data) => {
		return axios.post(`${API_ENDPOINTS.POST_DIET_PLAN_DETAILS}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),

				path: { mastuuid },
			},
		})
	},
	postPlanNameDetails: (mastuuid, data) => {
		return axios.post(`${API_ENDPOINTS.POST_PLAN_NAME_DETAILS_FOR_SETTINGS}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),

				path: { mastuuid },
			},
		})
	},
	updateDietPlan: (data, dietPlanUuid) => {
		return axios.put(API_ENDPOINTS.UPDATE_DIET_PLAN_DETAILS, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { dietPlanUuid } },
		})
	},
	getSettingDietPlan: (dietPlanUuid) => {
		return axios.get(`${API_ENDPOINTS.IMAGE_SETTING_DIET_PLAN}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dietPlanUuid } },
		})
	},
	deleteDietPlan: (dietPlanUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_DIET_PLAN_DETAILS}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { dietPlanUuid },
			},
		})
	},
	deletePlanNameDetailsforSetting: (dietPlanUuid, mastuuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_PLAN_NAME_DETAILS_FOR_SETTINGS}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { mastuuid, dietPlanUuid },
			},
		})
	},
	// Client Diet Plan
	unitLookUp: () => {
		return axios.get(`${API_ENDPOINTS.DIET_PLAN_UNIT_LOOKUP}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	ClientDietPlanFilterList: (data) => {
		return axios.post(`${API_ENDPOINTS.POSTGET_CLIENT_DIETPLAN_DETAILS}`, data, {
			headers: {
				isAuthRequired: true,
				'content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},
	postClientDietPlan: (mastuuid, custuuid, data) => {
		return axios.post(`${API_ENDPOINTS.POST_DIETPLAN_CLIENT_DETAILS}`, data, {
			headers: {
				isAuthRequired: true,
				'content-Type': 'application/json',
				'X-SECURITY': csrf(),
				path: { mastuuid, custuuid },
			},
		})
	},
	deleteClientDietPlan: (clientdietPlanUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_CLIENT_DIETPLAN_DETAILS}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { clientdietPlanUuid },
			},
		})
	},
	deletePlanNameDetails: (dietPlanUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_PLAN_NAME_DETAILS}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { dietPlanUuid },
			},
		})
	},
	getDropdownClientDietPlan: (mastuuid, custuuid, params) => {
		return axios.get(`${API_ENDPOINTS.DROPDOWN_DIET_PLAN_DETAILS}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { mastuuid, custuuid },
			},
			params: { ...params },
		})
	},
	getPrintClientDietPlan: (dietPlanUuid, params) => {
		return axios.get(`${API_ENDPOINTS.GET_PRINT_DIETPLAN}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',

				path: { dietPlanUuid },
			},
			params: { ...params },
		})
	},
	updateClientDietPlan: (data, clientdietPlanUuid) => {
		return axios.put(API_ENDPOINTS.UPDATE_CLIENT_DIETPLAN_DETAILS, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { clientdietPlanUuid } },
		})
	},
	getClientDietPlan: (clientdietPlanUuid) => {
		return axios.get(`${API_ENDPOINTS.IMAGE_CLIENT_DIET_PLAN}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { clientdietPlanUuid } },
		})
	},
	getEditPlanName: (dietPlanUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_PLAN_NAME_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dietPlanUuid } },
		})
	},
	activateDietPlan: (data) => {
		return axios.patch(`${API_ENDPOINTS.ACTIVATE_DIET_PLAN}`, data, {
			headers: {
				isAuthRequired: true,
				'content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},
	getDietPlanListForDashboard: (mastTentUuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.GET_DASHBOARD_DIETPLAN_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
			params: params,
		})
	},
	getClientFoodDetails: (planUuid, foodUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_CLIENT_ASSIGNED_FOOD}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { planUuid, foodUuid },
			},
		})
	},
	updateClientFood: (body, planUuid, foodUuid) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_CLIENT_FOOD}`, body, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { planUuid, foodUuid },
			},
		})
	},
	addClientFood: (body, tentUuid, custUuid, planUuid) => {
		return axios.post(`${API_ENDPOINTS.ADD_CLIENT_FOOD}`, body, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { tentUuid, custUuid, planUuid },
			},
		})
	},
	getDietFoodDetails: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_FOOD_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
