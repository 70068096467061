import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, MenuItem, Menu, IconButton, Tooltip } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateRangePicker from '@mui/lab/DateRangePicker'
import moment from 'moment'
import { useSelector } from 'react-redux'
import themesConfig from 'app/fuse-configs/themesConfig'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
	datePickerContainer: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiFormControl-root': {
			width: 120,
		},
		'& .Mui-focused': {
			color: 'inherit',
		},
		'& fieldset': {
			border: 'none',
		},
		'& input': {
			fontWeight: 500,
			fontSize: '1.34rem',
		},
		'& label': {
			background: '#FFF',
			paddingInline: 4,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 'unset',
		},
	},
	dropDown: {
		background: '#FFFFFF',
		border: '1px solid #ABB2B9',
		borderTopRightRadius: 6,
		borderBottomRightRadius: 6,
		'& .MuiSvgIcon-root': {
			color: '#5D6D7E !important',
		},
	},
	datePickerContainerBorder: {
		border: '1px solid #ABB2B9',
		background: '#FFFFFF',
		paddingBlock: 1,
		borderRightStyle: 'none',
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
		'& .MuiPaper-root': {
			zIndex: 40,
		},
	},
	menuContainer: {
		'& .MuiPaper-root': {
			width: 198,
		},
	},
	menuListItem: {
		marginInline: 12,
		marginBlock: 4,
		borderRadius: 4,
		'&:hover': {
			background: themesConfig.lyfngo.dropDown.light,
		},
	},
	selectedItem: {
		background: themesConfig.lyfngo.dropDown.main,
		'&:hover': {
			background: themesConfig.lyfngo.dropDown.main,
		},
	},
}))

function DateRangeSelector({ getDateRange, defaultRange }) {
	const classes = useStyles()
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat || {})

	const SelectableOptions = [
		{
			mastLookupKey: 'TDY',
			mastLookupValue: 'Today',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'L7D',
			mastLookupValue: 'Last 7 days',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'MTH',
			mastLookupValue: 'Last month',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'LYR',
			mastLookupValue: 'Last year',
			mastLookupType: 'WFL',
		},
	]

	const [value, setValue] = useState([
		moment()
			.subtract(defaultRange ? defaultRange : 1, 'M')
			.format(),
		moment().format(),
	])
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedIndex, setSelectedIndex] = useState(2)

	const open = Boolean(anchorEl)

	const handleClose = (event) => {
		setAnchorEl(null)
	}

	const dateFromSelect = (selectedOption) => {
		if (selectedOption === 'L7D') {
			setValue([moment().subtract(1, 'w').format(), moment().format()])
			getDateRange(`${moment().subtract(1, 'w').format('DD-MM-YYYY')}~${moment().format('DD-MM-YYYY')}`)
			setSelectedIndex(1)
		} else if (selectedOption === 'LYR') {
			setValue([moment().subtract(1, 'y').format(), moment().format()])
			getDateRange(`${moment().subtract(1, 'y').format('DD-MM-YYYY')}~${moment().format('DD-MM-YYYY')}`)
			setSelectedIndex(3)
		} else if (selectedOption === 'MTH') {
			setValue([moment().subtract(1, 'M').format(), moment().format()])
			getDateRange(`${moment().subtract(1, 'M').format('DD-MM-YYYY')}~${moment().format('DD-MM-YYYY')}`)
			setSelectedIndex(2)
		} else if (selectedOption === 'TDY') {
			setValue([moment().format(), moment().format()])
			getDateRange(`TDY`)
			setSelectedIndex(0)
		} else {
			if (moment(selectedOption[0]).format('DD-MM-YYYY') !== 'Invalid date' && moment(selectedOption[1]).format('DD-MM-YYYY') !== 'Invalid date') {
				// We discussed with java team if FROM DATE and TO DATE is same we should set TDY in API body else we set custom date range as it is
				if (moment(selectedOption[0]).format('DD-MM-YYYY') === moment(selectedOption[1]).format('DD-MM-YYYY')) {
					getDateRange(`TDY`)
					setSelectedIndex(0)
				} else {
					getDateRange(`${moment(selectedOption[0]).format('DD-MM-YYYY')}~${moment(selectedOption[1]).format('DD-MM-YYYY')}`)
					setSelectedIndex(10)
				}
			}
		}
		handleClose()
	}
	return (
		<div className={classes.datePickerContainer}>
			<Tooltip title={`Select range`} placement='top' arrow>
				<div className={classes.datePickerContainerBorder}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateRangePicker
							startText={''}
							endText={''}
							calendars={1}
							value={value}
							inputFormat={dateFormat}
							format={'yyyy-MM-DD'}
							minDate={new Date('2016-01-01')}
							disableFuture={true}
							onChange={(newValue) => {
								setValue(newValue)
								dateFromSelect(newValue)
							}}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField size='small' {...startProps} />
									<span> to </span>
									<TextField size='small' {...endProps} />
								</React.Fragment>
							)}
						/>
					</LocalizationProvider>
				</div>
			</Tooltip>
			<div className={classes.dropDown}>
				<IconButton
					id='basic-button'
					name='selectperiodofdate'
					className={classes.iconButton}
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
					onClick={(event) => {
						setAnchorEl(event.currentTarget)
					}}
					size='medium'
				>
					{open ? <ArrowDropUp /> : <ArrowDropDown />}
				</IconButton>
				<Menu
					id='basic-menu'
					className={classes.menuContainer}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					{SelectableOptions.map((option, idx) => (
						<MenuItem
							key={idx}
							value={option?.mastLookupKey}
							onClick={() => dateFromSelect(option?.mastLookupKey)}
							className={clsx(classes.menuListItem, idx === selectedIndex && classes.selectedItem)}
						>
							{option?.mastLookupValue}
						</MenuItem>
					))}
				</Menu>
			</div>
		</div>
	)
}

export default DateRangeSelector
