import React from 'react'
import { Grid, Autocomplete, TextField, Chip } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import _ from 'lodash'

const Medicalhistory = ({ medicalHistory, medicalData, setMedicalData, formik }) => {
	const filter = createFilterOptions()

	return (
		<Grid container>
			<Grid container>
				<Autocomplete
					id='medicalhistory'
					name='medicalhistory'
					fullWidth
					options={_.sortBy(medicalHistory, 'tentMedicalhistoryName') || []}
					multiple
					disableClearable={true}
					disableCloseOnSelect={true}
					isOptionEqualToValue={(option, value) => option?.tentMedicalhistoryName === value?.tentMedicalhistoryName}
					value={medicalData}
					limitTags={2}
					openOnFocus
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option
						}
						if (option.inputValue) {
							return option.inputValue
						}
						return option.tentMedicalhistoryName
					}}
					filterSelectedOptions
					filterOptions={(options, params) => {
						const filtered = filter(options, params)

						const { inputValue } = params
						const isExisting = options.includes((option) => inputValue === option.tentMedicalhistoryName)
						if (inputValue !== '' && !isExisting) {
							filtered.push({
								inputValue,
								isNew: true,
								tentMedicalhistoryName: `Add "${inputValue}"`,
							})
						}

						return filtered
					}}
					onChange={(e, newValue) => {
						if (typeof newValue === 'string') {
							setMedicalData({
								tentMedicalhistoryName: newValue?.inputValue,
							})
							formik?.setFieldValue('medicalhistory', newValue?.inputValue)
						} else if (newValue && newValue.inputValue) {
							setMedicalData({
								tentMedicalhistoryName: newValue.inputValue,
							})
							formik?.setFieldValue('medicalhistory', newValue?.inputValue)
						} else {
							setMedicalData(newValue)
							formik?.setFieldValue('medicalhistory', newValue)
						}
					}}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => <Chip variant='outlined' label={option?.tentMedicalhistoryName} size='small' {...getTagProps({ index })} />)
					}
					renderOption={(props, option) => <li {...props}>{option.tentMedicalhistoryName}</li>}
					freeSolo
					label='Medical history'
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								label='Medical history'
								color='secondary'
								className='mt-2 mb-2'
								size='small'
								error={formik.touched.medicalhistory && formik.errors.medicalhistory}
								helperText={formik.touched.medicalhistory && formik.errors.medicalhistory && formik.errors.medicalhistory}
								inputProps={{
									...params.inputProps,
									value: params.inputProps.value.replace(/[^A-Za-z0-9 ]/gi, ''),
								}}
							/>
						)
					}}
				/>
			</Grid>
		</Grid>
	)
}

export default Medicalhistory
