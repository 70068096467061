import { useEffect, useState } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles'
import { currencyCode, customDateFormat } from 'utils'
import { useSelector } from 'react-redux'
import { Typography, Button } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	tableContainer: {
		paddingBlock: 12,
		width: '100%',
		'& .MuiDataGrid-iconSeparator ': {
			display: 'none',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '600',
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-columnHeaderWrapper': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: '#EBF4FF',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF',
		},
		'& .MuiDataGrid-cell': {
			border: 'none',
			color: '#000',
		},
		'& .MuiDataGrid-toolbarContainer': {
			justifyContent: 'flex-start',
			paddingBlock: 8,
			paddingInline: 8,
			gap: 36,
			'& .MuiButton-root': {
				backgroundColor: '#0062DD',
				borderRadius: 8,
				paddingInline: 16,
			},
		},
		'& .stylingDataGridCellsPrice': {
			paddingInlineEnd: 22,
		},
		'& .stylingDataGridCellsText': {
			paddingInlineStart: 14,
		},
		'& .stylingDataGridCellsSno': {},
	},
	itemtext: {
		fontSize: 13,
		color: '#111827',
	},
	itemtextRight: {
		fontSize: 13,
		color: '#111827',
		textAlign: 'right',
		display: 'block',
		paddingInlineEnd: 22,
	},
}))

export default function SummaryTable(props) {
	const classes = useStyles()
	const {
		report,
		pageData,
		getColumn,
		getRow,
		isCare,
		//  pageSize, handlePageSizeChange, params, handleChangePage
	} = props
	const newRow = pageData?.items
	const practiceName = useSelector((state) => state?.dynamicMenu?.currentPractice?.practiceName)
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const currency = useSelector((state) => state?.dynamicMenu?.userData?.currencyCode)
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	const [rows, setRows] = useState([])
	const [columns, setColumns] = useState([])

	const [TemppageSize, setTempPageSize] = useState(10)

	const PatientsRow = _.map(newRow, (item, index) => ({
		id: index,
		sno: index + 1,
		patientname: item?.custName || '-',
		patientID: item?.custCustomId || '-',
		date: item?.createdOn ? customDateFormat(item?.createdOn, dateFormatForViewer) : '-',
		email: item?.custEmail || '-',
		age: item?.custAge || '-',
		gender: !_.isEmpty(item?.custGender) ? item?.custGender : '-',
		medicalCondition: item?.medicalHistoryName || '-',
		refferedBy: item?.custReferredBy || '-',
		occupation: item?.custOccupation || '-',
		membership: !_.isEmpty(item?.membershipName) ? item?.membershipName : '-',
		weight: !_.isEmpty(item?.weight) ? item?.weight : '-',
		height: !_.isEmpty(item?.height) ? item?.height : '-',
		address: !_.isEmpty(item?.address) ? item?.address : '-',
		phone: !_.isEmpty(item?.custMobileNo) ? item?.custMobileNo : '-',
	}))
	const PatientsColumns = [
		{ field: 'date', headerName: 'Date', width: 130, sortable: false },
		{
			field: 'patientname',
			headerName: `${isCare ? 'Patient name' : 'Client name'}`,
			width: 220,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'patientID',
			headerName: `${isCare ? 'Patient ID' : 'Client ID'}`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'phone',
			headerName: 'Mobile number',
			width: 230,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'email',
			headerName: 'Email ID',
			width: 230,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'address',
			headerName: 'Address',
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'age',
			headerName: 'Age',
			width: 130,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'gender',
			headerName: 'Gender',
			width: 130,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'occupation',
			headerName: 'Occupation',
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'weight',
			headerName: 'Weight',
			width: 150,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'height',
			headerName: 'Height',
			width: 150,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'medicalCondition',
			headerName: 'Medical condition',
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'refferedBy',
			headerName: 'Referred by',
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'membership',
			headerName: 'Membership',
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
	]

	const AppointmentRow = _.map(newRow, (item, index) => ({
		id: index,
		sno: index + 1,
		date: item?.scheduledOn ? customDateFormat(item?.scheduledOn, dateFormatForViewer) : '-',
		scheduledAt: item?.scheduledTime ? moment(item?.scheduledTime, ['h:mm A']).format('LT') : '-',
		checkin: item?.checkInTime ? moment(item?.checkInTime.slice(0, -1)).format('LT') : '-',
		engagedAt: item?.engageStartTime ? moment(item?.engageStartTime.slice(0, -1)).format('LT') : '-',
		waited: item?.waitingTime || '-',
		checkedAt: item?.checkOutTime ? moment(item?.checkOutTime.slice(0, -1)).format('LT') : '-',
		doctorname: `${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || '-'}`,
		patientname: item?.custName || '-',
		category: item?.categoryName || '-',
	}))
	const AppointmentColumns = [
		{ field: 'sno', headerName: 'S.No', width: 70, align: 'center', sortable: false },
		{ field: 'date', headerName: 'Date', width: 100, sortable: false },
		{ field: 'scheduledAt', headerName: 'Scheduled at', align: 'center', width: 120, sortable: false },
		{ field: 'checkin', headerName: 'Check in at', align: 'center', width: 114, sortable: false },
		{
			field: 'waited',
			headerName: 'Waited for [HH: MM: SS] ',
			description: 'Duration that patient waited since checked in',
			width: 200,
			align: 'center',
			sortable: false,
		},
		{ field: 'engagedAt', headerName: 'Engaged at', align: 'center', width: 110, sortable: false },
		{ field: 'checkedAt', headerName: 'Checked at', align: 'center', width: 110, sortable: false },
		{ field: 'patientname', headerName: `${isCare ? 'Patient' : 'Client'}`, width: 180, sortable: false, cellClassName: 'stylingDataGridCellsText' },
		{
			field: 'doctorname',
			headerName: 'Specialist',
			width: 160,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
	]

	const InvoicedDetailRow = _.map(newRow, (item, index) => ({
		id: index,
		sno: index + 1,
		patientname: item?.custName || '-',
		treatmentprocedure: item?.procedures || '-',
		cost: item?.custBillingCost || '-',
		discount: item?.custBillingDiscount || '-',
		tax: item?.custBillingTax || '-',
		invoiceCost: item?.custBillingTotal || '-',
		invoiceNo: item?.custInvoiceNo || '-',
		receiptNo: item?.custPaymentNo || '-',
		modeOfPayment: item?.paymentMode || '-',
		paymentReferenceNumber: item?.paymentReference || '-',
		amountPaid: item?.paymentAmount || '-',
		totalAmount: item?.custBillingTotal || '-',
		amountDue: item?.amountDue || '-',
		vendorFees: item?.vendorFeeInAmount || '-',
	}))

	const InvoicedDetailColumns = [
		{ field: 'sno', headerName: 'S.No', width: 70, align: 'center', sortable: true },
		{
			field: 'patientname',
			headerName: `${isCare ? 'Patient name' : 'Client name'}`,
			width: 180,
			sortable: true,
			cellClassName: 'stylingDataGridCellsText',
			// renderCell: (params) => {
			// 	return (
			// 		<Typography variant='subtitle1' className={classes.itemtext}>
			// 			{params.row.patientname}
			// 		</Typography>
			// 	)
			// },
		},
		{
			field: 'treatmentprocedure',
			headerName: `Procedures`,
			width: 210,
			sortable: true,
			renderCell: (params) => {
				return (
					<Typography variant='subtitle1' className={classes.itemtext}>
						{params.row.treatmentprocedure.replaceAll(',', ', ')}
					</Typography>
				)
			},
		},
		{ field: 'cost', headerName: `Cost (${currency})`, width: 140, type: 'number', sortable: true, cellClassName: 'stylingDataGridCellsPrice' },
		{
			field: 'discount',
			headerName: `Discount (${currency})`,
			width: 140,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{ field: 'tax', headerName: `Tax (${currency})`, width: 130, type: 'number', sortable: true, cellClassName: 'stylingDataGridCellsPrice' },
		{
			field: 'vendorFees',
			headerName: `Vendor fees (${currency})`,
			width: 160,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{ field: 'invoiceNo', headerName: 'Invoice No', width: 270, sortable: true, cellClassName: 'stylingDataGridCellsText' },
		{
			field: 'receiptNo',
			headerName: 'Receipt No',
			width: 270,
			sortable: true,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'modeOfPayment',
			headerName: 'Mode of payment',
			width: 154,
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'paymentReferenceNumber',
			headerName: 'Payment reference number',
			width: 202,
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},

		{
			field: 'amountPaid',
			headerName: `Amount received (${currency})`,
			width: 184,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'totalAmount',
			headerName: `Amount invoiced (${currency})`,
			width: 184,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},

		{
			field: 'amountDue',
			headerName: `Amount due (${currency})`,
			width: 160,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
	]

	const DistributorRow = _.map(pageData?.totalValue?.Data, (item, index) => ({
		id: index,
		sNo: index + 1,
		distributorName: item?.distributorName || '-',
		commissionName: item?.commissionName || '-',
		commissionValue: item?.finalCommissionValue || '-',
		productName: item?.tentProductName || '-',
		clientName: item?.custName || '-',
		roUserName: item?.roUserName || '-',
		clientID: item?.custCustomId || '-',
		invoiceNo: item?.custInvoiceNo || '-',
		receiptNo: item?.custPaymentNo || '-',
		paymentMode: item?.modeName || '-',
		paymentStatus: _.capitalize(item?.paymentStatus) || '-',
		billDate: item?.billCreatedDate ? customDateFormat(item?.billCreatedDate, dateFormatForViewer) : '-',
		productPrice: currencyCode(item?.productCost || 0, currencyType),
		tax: currencyCode(item?.productTax || 0, currencyType),
		discount: currencyCode(item?.productDiscount || 0, currencyType),
		finalPrice: currencyCode(item?.productTotalAmount || 0, currencyType),
		distributorCommissionValue: currencyCode(item?.distributorTotalCommission || 0, currencyType),
		ROCommissionValue: currencyCode(item?.distributorTotalRoCommission || 0, currencyType),
	}))

	const DistributorColumns = [
		{
			field: 'sNo',
			headerName: `S No`,
			width: 72,
			sortable: true,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'distributorName',
			headerName: `Distributor name`,
			width: 220,
			sortable: true,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'commissionName',
			headerName: `Commission name`,
			width: 220,
			sortable: true,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'commissionValue',
			headerName: `Commission value`,
			width: 170,
			sortable: true,
			renderCell: (params) => {
				return (
					<div style={{ width: 170, display: 'block' }}>
						<Typography variant='subtitle1' className={classes.itemtextRight}>
							{params.row.commissionValue}
						</Typography>
					</div>
				)
			},
		},
		{
			field: 'productName',
			headerName: `Product name`,
			width: 220,
			sortable: true,
		},
		{
			field: 'clientName',
			headerName: `Client name`,
			width: 220,
			sortable: true,
		},
		{
			field: 'clientID',
			headerName: `Client ID`,
			width: 220,
			sortable: true,
		},
		{
			field: 'invoiceNo',
			headerName: `Invoice no.`,
			width: 220,
			sortable: true,
		},
		{
			field: 'receiptNo',
			headerName: `Receipt no.`,
			width: 220,
			sortable: true,
		},
		{
			field: 'paymentMode',
			headerName: `Payment mode`,
			width: 220,
			sortable: true,
		},
		{
			field: 'paymentStatus',
			headerName: `Payment status`,
			width: 200,
			sortable: true,
		},
		{
			field: 'billDate',
			headerName: `Bill date`,
			width: 180,
			sortable: true,
		},
		{
			field: 'productPrice',
			headerName: `Product price (${currency})`,
			width: 162,
			sortable: true,
			type: 'number',
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'tax',
			headerName: `Tax (${currency})`,
			width: 148,
			sortable: true,
			type: 'number',
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'discount',
			headerName: `Discount (${currency})`,
			width: 148,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'finalPrice',
			headerName: `Final price (${currency})`,
			width: 148,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'distributorCommissionValue',
			headerName: `Distributor commission value (${currency})`,
			width: 170,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'roUserName',
			headerName: `RO name`,
			width: 220,
			sortable: true,
		},
		{
			field: 'ROCommissionValue',
			headerName: `RO commission value (${currency})`,
			width: 170,
			type: 'number',
			sortable: true,
			cellClassName: 'stylingDataGridCellsPrice',
		},
	]

	const MembershipRow = _.map(newRow, (item, index) => ({
		id: index,
		sno: index + 1,
		patientname: item?.custName || '-',
		patientID: item?.custCustomId || '-',
		dateofJoin: item?.joinedDate ? customDateFormat(item?.joinedDate, dateFormatForViewer) : '-',
		mobileNumber: `${item?.custCountryCode || ''}  ${item?.custMobileNo || ''}`,
		membershipName: item?.membershipName,
		membershipStatus: item?.membershipStatus,
		session: item?.totalSession || '-',
		validity: item?.validity || '-',
		startDate: item?.startDate ? customDateFormat(item?.startDate, dateFormatForViewer) : '-',
		endDate: item?.endDate ? customDateFormat(item?.endDate, dateFormatForViewer) : '-',
		expiryStatus: item?.expiryStatus,
		invoiceNo: item?.custInvoiceNo || '-',
		receiptNo: item?.custPaymentNo || '-',
		price: currencyCode(item?.price || 0, currencyType),
		paymentStatus: item?.paymentStatus || '-',
		paymentMode: item?.paymentMode || '-',
	}))

	const MembershipColumns = [
		{
			field: 'patientname',
			headerName: `${isCare ? 'Patient name' : 'Client name'}`,
			width: 220,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'patientID',
			headerName: `${isCare ? 'Patient ID' : 'Client ID'}`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'mobileNumber',
			headerName: `${isCare ? 'Patient mobile no.' : 'Client  mobile no.'}`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'dateofJoin',
			headerName: `Date of joined`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'membershipName',
			headerName: `Membership name`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'membershipStatus',
			headerName: `Membership status`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'validity',
			headerName: `Validity`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'session',
			headerName: `Session`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'startDate',
			headerName: `Start date`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'endDate',
			headerName: `End date`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'expiryStatus',
			headerName: `Expiry status`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
		{
			field: 'invoiceNo',
			headerName: `Invoice no.`,
			width: 220,
			sortable: false,
		},
		{
			field: 'paymentMode',
			headerName: `Payment Mode`,
			width: 180,
			sortable: false,
		},
		{
			field: 'price',
			headerName: `Price (${currency})`,
			width: 180,
			sortable: false,
			type: 'number',
			cellClassName: 'stylingDataGridCellsPrice',
		},
		{
			field: 'paymentStatus',
			headerName: `Payment status`,
			width: 200,
			sortable: false,
			cellClassName: 'stylingDataGridCellsText',
		},
	]

	useEffect(() => {
		if (_.isEqual(report, 'NewPatients')) {
			setRows(PatientsRow)
			setColumns(PatientsColumns)
			getRow(PatientsRow)
			getColumn(PatientsColumns)
		} else if (_.isEqual(report, 'Appointments')) {
			setRows(AppointmentRow)
			setColumns(AppointmentColumns)
			getRow(AppointmentRow)
			getColumn(AppointmentColumns)
		} else if (_.isEqual(report, 'InvoicedDetail')) {
			setRows(InvoicedDetailRow)
			setColumns(InvoicedDetailColumns)
			getRow(InvoicedDetailRow)
			getColumn([])
		} else if (_.isEqual(report, 'Distributor')) {
			setRows(DistributorRow)
			setColumns(DistributorColumns)
			getRow(DistributorRow)
			getColumn([])
		} else if (_.isEqual(report, 'Membership')) {
			setRows(MembershipRow)
			setColumns(MembershipColumns)
			getRow(MembershipRow)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report, newRow, pageData?.totalValue?.Data])

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarExport
					printOptions={{ disableToolbarButton: true }}
					csvOptions={{
						fileName: practiceName,
					}}
				/>
			</GridToolbarContainer>
		)
	}

	return (
		<div className={classes.root}>
			{!_.isEmpty(rows) && (
				<div className={classes.tableContainer}>
					{/* <DataGrid
						rows={rows}
						columns={columns}
						pageSize={pageSize}
						paginationModel={params}
						onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
						rowsPerPageOptions={[10, 20, 50, 100]}
						pagination={true}
						paginationMode='server'
						autoPageSize={true}
						autoHeight={true}
						disableSelectionOnClick
						components={{
							Toolbar: CustomToolbar,
						}}
						onPageChange={handleChangePage}
						disableColumnMenu={true}
						rowCount={pageData?.totalItems}
					/> */}
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={TemppageSize}
						onPageSizeChange={(newPageSize) => (newPageSize < 100 ? setTempPageSize(newPageSize) : setTempPageSize(100))}
						rowsPerPageOptions={[5, 10, 25, 50]}
						pagination={true}
						// autoPageSize={true}
						autoHeight={true}
						components={{
							Toolbar: CustomToolbar,
						}}
						disableSelectionOnClick
						disableColumnMenu={true}
					/>
				</div>
			)}
		</div>
	)
}
