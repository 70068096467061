import React from 'react'
import { useDropzone } from 'react-dropzone'
import { IconButton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
// import { getImgUrl, getResponseMessage } from 'utils'
import useStyles from './styles'
// import Button from '../../../../../../sharedComponents/Button'
// import fileApi from '../../../../../services/fileupload/GetApi'
import { showMessage } from 'app/store/fuse/messageSlice'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
// import { updateProfilePhoto } from 'app/session/actions'
const CompDropZone = (props) => {
	const { fileName, setFileName, setUploadDetails, uploadFiles } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 15360000
	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxSize: formatBytes(maxFileSize),
		maxFiles: 1,
		accept: '.xlsx,.xls,.csv,.pdf',
		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (_.isEmpty(acceptedFiles)) {
				dispatch(
					showMessage({
						message: 'This file type is not allowed',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else if (fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				uploadFiles(acceptedFiles)
			}
		},
	})
	return (
		<div
			className={classes.upload}
			onClick={() => {
				if (!fileName) {
					open()
				}
			}}
		>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{fileName ? (
					<div className={classes.fileName}>
						<Typography variant='h6' pt={1}>
							{fileName}
						</Typography>
						<IconButton
							onClick={(e) => {
								e.stopPropagation()
								setFileName('')
								setUploadDetails([])
							}}
						>
							<DeleteIcon />
						</IconButton>
					</div>
				) : (
					<>
						<CloudUploadOutlinedIcon className={classes.uploadIcon} />
						<Typography variant='body1' pb={1}>
							.xls .xlsx .csv .pdf - Max file size: 5MB
						</Typography>
					</>
				)}
			</div>
		</div>
	)
}

export default CompDropZone
