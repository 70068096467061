import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'
const CancelToken = axios.CancelToken
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getPatientId: (uuid) => {
		return axios.get(API_ENDPOINTS.PATIENT_ID_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	getCustomerStatus: (custUuid) => {
		return axios.get(API_ENDPOINTS.PATIENT_CLIENT_STATUS_DETAILS, {
			headers: { isAuthRequired: true, 'Content-type': 'application/json', path: { custUuid } },
		})
	},
	savePatientDetails: (publicKey, ivKey, uuid) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.SAVE_PATIENT_DETAILS}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}`, isAuthRequired: true, path: { uuid } },
		})
	},
	updatePatientdetails: (publicKey, ivKey, custUuid) => {
		const result = { data: publicKey }
		return axios.put(`${API_ENDPOINTS.UPDATE_PATIENT_DETAILS}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}`, isAuthRequired: true, CustUuid: custUuid },
		})
	},
	updateCalenderViewMode: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.patch(`${API_ENDPOINTS.CALENDER_VIEW_MODE_CHANGE}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, key: `${ivKey}` },
		})
	},

	getCalenderView: (uuid) => {
		return axios.get(API_ENDPOINTS.CALENDER_VIEW_MODE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},

	getGroupDetails: (tentMastUuid, params) => {
		return axios.get(API_ENDPOINTS.GROUP_GET_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentMastUuid } },
			params: { ...params },
		})
	},
	saveRelationShipDetails: (publicKey, ivKey, custUuid) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.SAVE_RELATIONSHIP_DETAILS}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}`, isAuthRequired: true, path: { custUuid } },
		})
	},
	getCustomerDetails: (uuid, params) => {
		return axios.get(API_ENDPOINTS.CUSTOMER_LIST, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { uuid } },
			params: params,
		})
	},
	getCustomerListDetails: (body, mastuuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.post(API_ENDPOINTS.CUSTOMER_LIST_VIEW, body, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { mastuuid } },
			params: params,
		})
	},
	getPatientsList: (mastuuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.CUSTOMER_WHATAPP_TEMPLATE_LIST, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastuuid } },
			params: params,
		})
	},
	getCustomerStatusDetailsNoFilter: (uuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.CUSTOMER_STATUS_LIST_NO_FILTER, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { uuid } },
			params: params,
		})
	},
	getCustomerStatusDetails: (body, uuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.post(API_ENDPOINTS.CUSTOMER_STATUS_LIST, body, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { uuid } },
			params: params,
		})
	},
	saveGroupDetails: (data, custUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_GROUP_DETAILS}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, CustUuid: custUuid, path: { custUuid } },
		})
	},
	getCustomerMasterDetails: (custUuid) => {
		return axios.get(API_ENDPOINTS.GET_CUSTOMER_DETAILS, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { custUuid } },
		})
	},
	deleteCustomerDetails: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_CUSTOMER_DETAILS}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),

				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
	deleteMedicalhistory: (medhistUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_MEDICAL_HISTORY}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { medhistUuid },
			},
		})
	},
	getMedicalHistory: (uuid) => {
		return axios.get(API_ENDPOINTS.CUSTOMER_MEDICAL_HISTORY, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { uuid } },
		})
	},
	getTagsData: (uuid) => {
		return axios.get(API_ENDPOINTS.CUSTOMER_TAGS_DATA, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { uuid } },
		})
	},
	getCustMedicalHistory: (custUuid) => {
		return axios.get(API_ENDPOINTS.CUST_MEDICAL_HISTORY, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { custUuid } },
		})
	},
	saveMedicalHistory: (publicKey, ivKey, uuid, custUuid) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.SAVE_MEDICAL_HISTORY}`, result, {
			headers: {
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				isAuthRequired: true,
				CustUuid: custUuid,
				path: { uuid, custUuid },
			},
		})
	},
	saveTagsData: (result, uuid, custUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_CLIENT_TAGS}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, CustUuid: custUuid, path: { uuid, custUuid } },
		})
	},
	saveCustomersByMobNo: (data) => {
		return axios.post(`${API_ENDPOINTS.SAVE_CUSTOMER_BY_MOBILE_NUMBER}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	getSpecificCustomerDetails: (custUuid) => {
		return axios.get(API_ENDPOINTS.SPECIFIC_CUSTOMER_DETAILS, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { custUuid } },
		})
	},
	getMedeAccessRole: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_MEDE_ACCESSROLE, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastuuid } },
		})
	},
	getOccupationMasterData: (mastuuid) => {
		return axios.get(API_ENDPOINTS.OCCUPATION_API, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastuuid } },
		})
	},
	getTentUserByRole: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_TENTUSER_BY_ROLE, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastuuid } },
		})
	},
	getChartingRecords: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.GET_CHARTING_RECORDS_FOR_APPOINTMENT, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { appointmentUuid } },
		})
	},
	getTimeDuration: (params) => {
		return axios.get(API_ENDPOINTS.GET_TIME_DURATION, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
			params,
		})
	},
	getCompProcedureData: (appointmentUuid, mastUuid) => {
		return axios.get(API_ENDPOINTS.GET_COMP_PROCEDURE_DATA, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { appointmentUuid, mastUuid } },
		})
	},
	getTreatmentPlanData: (appointmentUuid, mastUuid) => {
		return axios.get(API_ENDPOINTS.GET_TREATMENT_PLAN_DATA, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { appointmentUuid, mastUuid } },
		})
	},

	saveShareRecords: (data) => {
		return axios.post(`${API_ENDPOINTS.SHARE_RECORDS}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	saveShareBill: (data) => {
		return axios.post(`${API_ENDPOINTS.SHARE_BILL}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	getDiscountSymbols: (mastTentUuid) => {
		return axios.get(API_ENDPOINTS.DISCOUNT_TYPE_SYMBOLS, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastTentUuid } },
		})
	},
	getViewRecordHistory: (data) => {
		return axios.post(`${API_ENDPOINTS.VIEW_RECORD_HISTORY}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	getNewViewRecordHistory: (mastuuid, custUuid) => {
		return axios.get(`${API_ENDPOINTS.VIEW_RECORD_HISTORY_NEW}`, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { mastuuid, custUuid } },
		})
	},
	getFileRecordHistory: (tentMastUuid, custUuid, body) => {
		return axios.post(API_ENDPOINTS.VIEW_FILE_RECORD_HISTORY, body, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { tentMastUuid, custUuid } },
		})
	},
	getCustBillingHistory: (mastTentUuid, custUuid, body, params) => {
		return axios.post(API_ENDPOINTS.GET_CUSTOMER_BILLING_HISTORY, body, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { mastTentUuid, custUuid } },
			params,
		})
	},

	// Google Import
	getGoogleMultiContact: (params) => {
		return axios.get(API_ENDPOINTS.GET_MULTI_CONTACTS, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
			params: { ...params },
		})
	},
	postGoogleMultiContact: (body, params) => {
		return axios.post(API_ENDPOINTS.POST_MULTI_CONTACTS, body, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
			params: { ...params },
		})
	},
	getGoogleSyncContact: (params) => {
		return axios.get(API_ENDPOINTS.GET_SYNC_CONTACTS, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
			params: { ...params },
		})
	},
	updatePatientStatus: (mastUuid, custUuid, progressStatus) => {
		return axios.put(
			API_ENDPOINTS.CHANGE_CUSTOMER_STATUS,
			{},
			{
				headers: {
					isAuthRequired: true,
					'X-SECURITY': csrf(),
					'Content-Type': 'application/json',
					CustUuid: custUuid,
					path: { mastUuid, custUuid },
				},
				params: { progressStatus: progressStatus },
			}
		)
	},
}
