import React from 'react'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
import { Avatar, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { setCurrentSelectedClient } from 'services/patient/roleBasedUuid/action'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	textfield: {
		'& .MuiFormControl-root': {
			width: '100% !important',
		},
		'& .MuiOutlinedInput-root': {
			paddingBlock: 2,
			borderRadius: 10,
		},
	},
	dropDown: {
		position: 'absolute',
		zIndex: 50,
		backgroundColor: '#fff',
		paddingInline: 8,
		boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
		borderRadius: 10,
		maxHeight: 320,
		overflowY: 'auto',
		width: '100%',
	},
	clients_list_item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		paddingBlock: 4,
		marginBlock: 3,
		borderRadius: 8,
		'&:hover': {
			background: themesConfig.lyfngo.dropDown.light,
			cursor: 'pointer',
		},
	},
	addOption: {
		marginBottom: 4,
	},
	circular_progress: {
		color: themesConfig.greyDark.palette.primary.main,
	},
}))

function ClientSearcher(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const {
		clientState,
		clientDispatch,
		performClientSearch,
		getClientId,
		formik,
		isCareFlow,
		countryCodeList,
		setCountryCode,
		setCountryCodeLength,
		setCountryCodeMinLength,
		isEditAppt,
		preSelectedClient,
	} = props

	const numericFormat = /^[0-9]+$/

	const handleFocus = () => {
		clientDispatch({
			type: 'setIsShowDropdown',
			data: true,
		})
	}

	const handleCloseDropdown = () => {
		clientDispatch({
			type: 'setIsShowDropdown',
			data: false,
		})
		clientDispatch({
			type: 'setClientDataList',
			data: [],
		})
	}

	const handleClear = () => {
		clientDispatch({
			type: 'setClientSearchText',
			data: '',
		})
		clientDispatch({
			type: 'setclientStatus',
			data: '',
		})
		clientDispatch({
			type: 'setIsNewClient',
			data: false,
		})
		formik?.setFieldValue('clientName', null)
		formik?.setFieldValue('clientId', '')
		formik?.setFieldValue('custMobile', '')
		formik?.setFieldValue('clientEmail', '')

		handleCloseDropdown()
	}

	const handleSelectClient = (value) => {
		dispatch(setCurrentSelectedClient(null))
		clientDispatch({
			type: 'setClientSearchText',
			data: value?.custName,
		})
		clientDispatch({
			type: 'setclientStatus',
			data: value?.leadStatus,
		})
		clientDispatch({
			type: 'setIsNewClient',
			data: false,
		})
		formik?.setFieldValue('clientName', value)
		formik?.setFieldValue('clientId', value?.custCustomId)
		formik?.setFieldValue('custMobile', value?.custMobileNo)
		formik?.setFieldValue('clientEmail', value?.custEmail)
		// setting the  country code and countryCodeLength
		let code = _.find(countryCodeList, { mastLookupKey: value?.custCountryCode })
		setCountryCode(code)
		setCountryCodeLength(parseInt(code?.mastLookupValue))
		setCountryCodeMinLength(parseInt(code?.minimumLength))
		handleCloseDropdown()
	}

	const handleAddClient = (newClientName) => {
		dispatch(setCurrentSelectedClient(null))
		formik?.setFieldValue('clientName', newClientName)
		clientDispatch({
			type: 'setIsNewClient',
			data: true,
		})
		getClientId()
		handleCloseDropdown()
	}

	const handleAddMobileNo = (newClientMobileNo) => {
		dispatch(setCurrentSelectedClient(null))
		formik?.setFieldValue('custMobile', newClientMobileNo)
		clientDispatch({
			type: 'setIsNewClient',
			data: true,
		})
		clientDispatch({
			type: 'setClientSearchText',
			data: '',
		})
		clientDispatch({
			type: 'setclientStatus',
			data: '',
		})
		getClientId()
		handleCloseDropdown()
	}

	React.useEffect(() => {
		_.isEmpty(clientState?.clientSearchText) && handleCloseDropdown()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientState?.clientSearchText])

	return (
		<div className={classes.root}>
			<div className={classes.textfield}>
				<TextField
					style={{ width: 300 }}
					id='ClientSearcher'
					color='secondary'
					size='small'
					label={`Search by name / Mobile number / Add ${isCareFlow ? 'patient' : 'client'}`}
					placeholder='Example - John doe or 9876543210'
					disabled={isEditAppt}
					value={clientState?.clientSearchText}
					onChange={(event) => {
						let text = event.target.value.replace(/[^A-Za-z0-9 ]/gi, '')
						clientDispatch({
							type: 'setClientSearchText',
							data: text,
						})
						if (text.trim().length > 2) {
							performClientSearch(text)
						}
						clientDispatch({
							type: 'setIsShowDropdown',
							data: true,
						})
						formik?.setFieldValue('clientEmail', '')
					}}
					onFocus={handleFocus}
					// onBlur={handleCloseDropdown}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{clientState?.isClientLoading && <CircularProgress color='inherit' className={classes.circular_progress} size={20} />}
								{_.isEmpty(clientState?.clientSearchText.trim()) ? (
									<IconButton disabled={isEditAppt || !_.isEmpty(preSelectedClient)}>
										<SearchIcon />
									</IconButton>
								) : (
									<IconButton disabled={isEditAppt || !_.isEmpty(preSelectedClient)} onClick={handleClear}>
										<CloseIcon />
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			</div>

			{clientState?.isShowDropdown && (
				<div className={classes.dropDown}>
					{!_.isEmpty(clientState?.clientsDataList) &&
						clientState?.clientsDataList?.map((option) => (
							<div
								className={classes.clients_list_item}
								key={option?.custUuid}
								onClick={(e) => {
									handleSelectClient(option)
								}}
							>
								<Avatar
									alt='client'
									src={option?.custThumbNailPath || `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
									style={{ height: '34px', width: '34px', marginRight: 10 }}
								/>
								<div>
									<Typography>
										{option.custName} {option?.custCustomId ? `(${option?.custCustomId})` : ''}
									</Typography>
									<Typography>
										{option.custCountryCode} {option.custMobileNo}
									</Typography>
								</div>
							</div>
						))}

					{clientState?.clientSearchText.trim().length > 2 && !clientState?.clientSearchText.trim().match(numericFormat) && (
						<div className={classes.clients_list_item} onClick={() => handleAddClient(clientState?.clientSearchText)}>
							<Avatar
								alt='client'
								src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
								style={{ height: '34px', width: '34px', marginRight: 10 }}
							/>
							<Typography className={classes.addOption}>
								Add {isCareFlow ? 'patient' : 'client'} "<b>{clientState?.clientSearchText}</b>"
							</Typography>
						</div>
					)}

					{clientState?.clientSearchText.trim().match(numericFormat) && (
						<div className={classes.clients_list_item} onClick={() => handleAddMobileNo(clientState?.clientSearchText)}>
							<Avatar
								alt='client'
								src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
								style={{ height: '34px', width: '34px', marginRight: 10 }}
							/>
							<Typography className={classes.addOption}>
								Add mobile "<b>{clientState?.clientSearchText}</b>"
							</Typography>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default ClientSearcher
