import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants/index'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getClientMembership: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_MEMBERSHIP_DETAILS_OF_CLIENT}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getClientMembershipDropdown: (mastTentUuid) => {
		return axios.get(API_ENDPOINTS.GET_MEMBERSHIP_DROPDOWNLIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
		})
	},
	updateClientMembership: (body, clientMembershipUuid) => {
		return axios.put(API_ENDPOINTS.UPDATE_CLIENT_MEMBERSHIP, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { clientMembershipUuid } },
		})
	},
	getClientEditMembershipDetails: (tentMembershipUuid, clientMembershipUuid) => {
		return axios.get(API_ENDPOINTS.GET_EDIT_MEMBERSHIP_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentMembershipUuid, clientMembershipUuid } },
		})
	},
	postAddMembershipForClient: (data) => {
		return axios.post(
			`${API_ENDPOINTS.POST_CLIENT_ADD_MEMBERSHIP}`,
			{ ...data },
			{ headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' } }
		)
	},

	getMembershipListForSettings: (mastTentUuid, params) => {
		return axios.get(API_ENDPOINTS.GET_MEMBERSHIP_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
			params: params,
		})
	},

	getMembershipListForDashboard: (mastTentUuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.GET_DASHBOARD_MEMBERSIHP_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
			params: params,
		})
	},

	deleteMembership: (tentMembershipUuid) => {
		return axios.delete(API_ENDPOINTS.DELETE_MEMBERSHIP, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { tentMembershipUuid } },
		})
	},

	addMembership: (body, mastTentUuid) => {
		return axios.post(API_ENDPOINTS.POST_ADD_MEMBERSHIP, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastTentUuid } },
		})
	},

	updateMembership: (body, tentMembershipUuid) => {
		return axios.put(API_ENDPOINTS.PUT_UPDATE_MEMBERSHIP, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { tentMembershipUuid } },
		})
	},

	getClientMembershipBillingData: (clientMembershipUuid) => {
		return axios.get(API_ENDPOINTS.GET_DETAILS_FOR_BILLING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { clientMembershipUuid } },
		})
	},

	getClientMembershipRenewBillingData: (clientMembershipUuid) => {
		return axios.get(API_ENDPOINTS.GET_DETAILS_FOR_RENEW_BILLING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { clientMembershipUuid } },
		})
	},

	putMembershipActiveInactive: (clientMembershipUuid, body) => {
		return axios.put(API_ENDPOINTS.PUT_MEMBERSHIP_TOGGLE, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { clientMembershipUuid } },
		})
	},

	saveMembershipAddbill: (body, mastTentUuid, custUuid, tentUserUuid) => {
		return axios.post(API_ENDPOINTS.POST_MEMBERSHIP_BILLING, body, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				Custuuid: custUuid,
				path: { mastTentUuid, custUuid, tentUserUuid },
			},
		})
	},

	billingdropdownmembershipList: (body) => {
		return axios.post(API_ENDPOINTS.GETTING_BILLING_MEMBERSHIP_DROPDOWN_BY_GET, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	getClientBillingData: (mastTentUuid, custUuid) => {
		return axios.get(API_ENDPOINTS.GET_DETAILS_FOR_BILLING_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid, custUuid } },
		})
	},

	getMembershipBilling: (mastTentUuid, custUuid, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(`${API_ENDPOINTS.GET_DETAILS_FOR_BILLING_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid, custUuid } },
			params: params,
		})
	},
}
