import React, { useCallback, useRef, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import useStyles from './style'
import { TextField, Typography, Avatar, Button, FormHelperText, IconButton, Tooltip, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import Autocomplete from '@mui/material/Autocomplete'
import MyLocation from './Location/Mylocation'
import ButtonComp from '../../../sharedComponents/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSelector, useDispatch } from 'react-redux'
import SlotBook from './slotBooking'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import establishmentApi from '../../../../services/ProfileSetup/EstablishmentDetails/api'
import GetEstablishmentDetails from '../../../../services/ProfileSetup/EstablishmentDetails/api'
import CompDropZone from '../../../sharedComponents/DropzoneUpload/Dropzone'
import fileUploadDetailsApi from '../../../../services/fileupload/GetApi'
import sendUploadFileApi from '../../../../services/fileupload/PostApi'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage, getImgUrl } from '../../../../utils'
import _ from 'lodash'
import { showLoader, hideLoader } from '../../../../services/loader/action'
import { setEstablishmentListData } from 'services/ProfileSetup/list/actions'
import moment from 'moment'
import secureLocalStorage from 'react-secure-storage'
import countryLookup from 'services/register/api'
import AdvancedAutocomplete from 'app/sharedComponents/AdvancedAutocomplete'
import lookupApi from 'services/welcomeCare/api'
import { FormatBold, FormatItalic } from '@mui/icons-material'
import RichTextEditor from 'app/sharedComponents/RichTextEditor/RichTextEditor'
import DropZone from './SpecializationDropzone'

function EstablishmentDetails(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const cancelExecutor = useRef()
	const token = useSelector((state) => state?.Authentication?.BearerToken)
	const { tentLatitude, tentLongitude, moveBackTab, moveNextTab, match } = props
	const action = _.get(match, 'params.action', '')
	const isEditMode = true
	const [coordinates, setCoordinates] = useState({ lat: 20.5937, lng: 78.9629 })
	const [zoom, setZoom] = useState(2)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const [establishmentCityOptions, setEstablishmentCityOptions] = useState([])
	const countryName = useSelector((state) => state?.dynamicMenu?.userData?.countryName)
	const [minMobileLen, setMinMobileLen] = useState(null)
	const [maxMobileLen, setMaxMobileLen] = useState(null)
	const [establishmentDetails, setEstablishmentDetails] = useState([])
	const [uploadPhoto, setUploadPhoto] = useState([])
	const [specialisationDetails, setSpecialisationDetails] = useState([])
	const [hideForm, setHideForm] = useState(false)
	const [countryOptions, setCountryOptions] = useState([])
	const [establishDetailImgRemove, setEstablishDetailImgRemove] = useState(false)
	const [countryLoading, setCountryLoading] = useState(false)
	const [cityLoading, setCityLoading] = useState(false)
	const [specialityChip, setSpecialityChip] = useState([])
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid, phoneNumberLength } = state
	// const bodyEditableRef = useRef(null)

	const groupUser = useSelector((state) => state?.GroupUser?.groupUserName || {})
	// const establishmentList = useSelector((state) => state?.profileSetup?.establishment?.list || [])
	const formData = useSelector((state) => state?.profileSetup?.establishment?.formData || {})
	const profilePic = secureLocalStorage.getItem('imgUuid')
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState([])
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || 'IPG')
	const [weekDays, setWeekDays] = useState([
		{ id: 1, text: 'S', name: 'Sunday', isSelected: false },
		{ id: 2, text: 'M', name: 'Monday', isSelected: false },
		{ id: 3, text: 'T', name: 'Tuesday', isSelected: false },
		{ id: 4, text: 'w', name: 'wednesday', isSelected: false },
		{ id: 5, text: 'T', name: 'Thursday', isSelected: false },
		{ id: 6, text: 'F', name: 'Friday', isSelected: false },
		{ id: 7, text: 'S', name: 'Saturday', isSelected: false },
	])
	const [selectedDays, setSelectedDays] = useState([])
	const errorJson = useSelector((state) => state?.errorMessages)
	const submitType = useRef('')
	const tentTypeId = useSelector((state) => state?.Authentication?.tentTypeId || secureLocalStorage.getItem('tentTypeId'))
	const [selectedSpecialization, setSelectedSpecialization] = useState(0)

	const onClickHideForm = () => {
		setHideForm(true)
	}
	const showPosition = (position) => {
		setCoordinates({ lat: position.coords.latitude, lng: position.coords.longitude })
		setZoom(14)
	}

	const onDragEnd = (e) => {
		const lat = e.latLng.lat()
		const lng = e.latLng.lng()
		setCoordinates({ lat, lng })
	}

	const addSlot = (e) => {
		slotFormik?.resetForm()
		let selectedDate = []
		_.map(weekDays, (item) => {
			if (item?.isSelected) {
				selectedDate.push({ ...item })
			}
		})

		setSlotBooking((prevState) => [
			...prevState,
			{
				operationAt: e?.operationAt,
				closeAt: e?.closeAt,
				selectDay: selectedDate,
				weekDays: weekDays,
				paymentOptions: e?.paymentOptions,
			},
		])

		slotFormik.setFieldValue('operationAt', '')
		slotFormik.setFieldValue('closeAt', null)
		setSelectedDays((prevState) => [...prevState, ...selectedDate])

		const resetWeeks = _.map(weekDays, (item) => {
			return { ...item, isSelected: false }
		})
		setWeekDays(resetWeeks)
	}

	const saveEstablishmentDetails = (e) => {
		dispatch(showLoader('Loading please wait...'))
		let selectedDate = []
		_.map(weekDays, (item) => {
			if (item?.isSelected) {
				selectedDate.push({ ...item })
			}
		})
		const slotSec = !hideForm
			? [
					...slotbooking,
					{
						operationAt: moment(slotFormik?.values?.operationAt).format('HH:mm:ss'),
						closeAt: moment(slotFormik?.values?.closeAt).format('HH:mm:ss'),
						selectDay: selectedDate,
						weekDays: weekDays,
						paymentOptions: slotFormik?.values?.paymentOptions,
					},
			  ]
			: slotbooking

		const fecilityList = _.map(ammenitiesChip, (fec) => ({
			status: true,
			progressStatus: progressStatus,
			isAmend: false,
			profileFacilitesUuid: fec?.profileFacilitesUuid || null,
			facilitesName: fec?.mastFacilityName || fec?.facilitesName,
		}))

		// const operationalHours = _.map(slotSec, (slot) => ({
		// 	status: true,
		// 	progressStatus: progressStatus,
		// 	isAmend: false,
		// 	parentId: null,
		// 	operationAt: moment(slot?.operationAt, 'HH:mm:ss').format('HH:mm:ss'),
		// 	closesAt: moment(slot?.closeAt, 'HH:mm:ss').format('HH:mm:ss'),
		// 	isSun: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Sunday'))?.isSelected || false,
		// 	isMon: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Monday'))?.isSelected || false,
		// 	isTue: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Tuesday'))?.isSelected || false,
		// 	isWed: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'wednesday'))?.isSelected || false,
		// 	isThu: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Thursday'))?.isSelected || false,
		// 	isFri: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Friday'))?.isSelected || false,
		// 	isSat: _.find(slot?.selectDay, (d) => _.isEqual(d?.name, 'Saturday'))?.isSelected || false,
		// 	upi: slot?.paymentOptions?.Option1,
		// 	creditOrDebitCard: slot?.paymentOptions?.Option2,
		// 	onlinePayment: slot?.paymentOptions?.Option3,
		// 	aidivaWallet: slot?.paymentOptions?.Option4,
		// 	atVenue: slot?.paymentOptions?.Option5,
		// 	cheque: slot?.paymentOptions?.Option6,
		// 	// online: slot?.paymentOptions?.Option7,
		// 	profileOpsHrsUuid: slot?.profileOpsHrsUuid || null,
		// }))

		const splDto = _.map(specialityChip, (item) => ({
			specialityUuid: item.specialityUuid,
			activeStatus: true,
		}))

		const docId = _.map(formik?.values?.uploadImage, (img) => ({ docUuid: img?.uuid }))
		const getDocId = _.map(establishmentDetails?.supportingDocUuid, (item, i) => item)

		const merged = getDocId.concat(docId)

		const encodedFormikAbout = encodeURIComponent(formik?.values?.WriteAbout) || null
		const encodedEstablishmentAbout = encodeURIComponent(establishmentDetails?.about) || null

		const body = {
			about: encodeURIComponent(formik?.values?.WriteAbout) || null,
			addressOne: formik?.values?.profileEstablishmentStreet || establishmentDetails?.addressOne,
			addressTwo: formik?.values?.profileEstablishmentAddressTwo || establishmentDetails?.addressTwo,
			amend: false || establishmentDetails?.amend,
			country: formik?.values?.profileEstablishmentCountry || establishmentDetails?.country,
			state: formik?.values?.profileEstablishmentState || establishmentDetails?.state,
			facilitiesList: fecilityList,
			locality: formik?.values?.profileEstablishmentLocality || establishmentDetails?.locality || null,
			mastTentTypeId: establishmentDetails?.mastTentTypeId || 0,
			mastTentUuid: mastuuid,
			masterDetailsUuid: establishmentDetails?.masterDetailsUuid || null,
			operationalHours: [],
			parentId: establishmentDetails?.parentId || null,
			pincode: formik?.values?.profileEstablishmentPinCode || establishmentDetails?.pincode || null,
			progressStatus: progressStatus || establishmentDetails?.progressStatus,
			specialitesDTOS: splDto,
			supportingDocUuid: merged || [],
			tentContactNo: formik?.values?.profileEstablishmentPhoneNo || establishmentDetails?.tentContactNo || null,
			tentEmail: formik?.values?.profileEstablishmentEmail || establishmentDetails?.tentEmail || '',
			tentLatitude: coordinates?.lat || null,
			tentLogo: establishmentDetails?.tentLogo || _.isEqual(groupUser, 'owner') ? profilePic : null,
			tentLongitude: coordinates?.lng || null,
			profileHeaderUuid: profileHeaderUuid,
			tentName: formik?.values?.profileEstablishmentName || establishmentDetails?.tentName || '',
			// specialization: formik?.values?.specialization,
			specialization: formik?.values?.specialization.map((item) => ({
				specializationName: item.specializationName || null,
				specializationDesc: item.specializationDesc || null,
				tentSpecializationUuid: item.tentSpecializationUuid || null,
				docDriveUuid: item?.docDriveUuid || null,
			})),
			isShowSpecialization: true,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				moveNextTab()
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res?.data?.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		// if (!hideForm && _.isEmpty(slotFormik?.values?.operationAt) && _.isEmpty(slotFormik?.values?.closeAt) && _.isEmpty(slotFormik?.values?.days)) {
		// 	dispatch(hideLoader())
		// 	slotFormik.handleSubmit(e)
		// } else {
		establishmentApi.updateEstablishment(uuid, body).then(onSuccess, onFailure)
		// }
	}

	const formik = useFormik({
		initialValues: {
			profileEstablishmentName: establishmentDetails.tentName || '',
			profileEstablishmentPhoneNo: establishmentDetails.tentContactNo || '',
			profileEstablishmentEmail: establishmentDetails.tentEmail || '',
			profileEstablishmentStreet: establishmentDetails?.addressOne || '',
			profileEstablishmentAddressTwo: establishmentDetails?.addressTwo || '',
			profileEstablishmentCountry: establishmentDetails?.country || '',
			profileEstablishmentState: establishmentDetails?.state || '',
			profileEstablishmentPinCode: establishmentDetails?.pincode || '',
			SearchandAdd: [],
			WriteAbout: '',
			AddAmminities: [],
			uploadImage: [],
			uploadVideo: '',
			specialization: [],
			isShowSpecialization: false,
		},
		validationSchema: yup.object({
			profileEstablishmentName: yup
				.string('value must be a string')
				.required('Establishment name is required')
				// .max(50, 'Max 50 characters only')
				.matches(/^[aA-zZ0-9\s]+$/, 'Only characters and numbers are allowed'),
			profileEstablishmentPhoneNo: yup
				.string()
				.required('Phone no is required')
				.nullable()
				.matches(/^[0-9\s]+$/, 'Only Numbers are allowed')
				.min(minMobileLen, `Min ${minMobileLen} number required`)
				.max(maxMobileLen, `Min ${maxMobileLen} number only`),
			profileEstablishmentEmail: yup.string('value must be a string').required('Email id is required').email('Please enter a valid email address'),
			profileEstablishmentStreet: yup
				.string('value must be a string')
				.nullable()
				.required('Address line 1 is required')
				.max(300, 'Max 300 characters only')
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters'),
			profileEstablishmentAddressTwo: yup
				.string('value must be a string')
				.nullable()
				.max(300, 'Max 300 characters only')
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters'),
			profileEstablishmentCountry: yup.string('value must be a string').nullable().required('Country is required'),
			profileEstablishmentState: yup.string('value must be a string').nullable().required('State is required'),
			// SearchandAdd: isEditMode ? yup.array().nullable() : yup.array().min(1, 'Please add any one').nullable().required('Please add any one'),
			SearchandAdd: !_.isEmpty(specialityChip)
				? yup.array().nullable()
				: yup.array().min(1, 'Please add any one').nullable().required('Please add any one'),
			profileEstablishmentPinCode: yup
				.string()
				// .required('Please enter the Pincode')
				.matches(/^[0-9\s]+$/, 'Only numbers are allowed')
				.min(3, 'Pincode should be minimum 3 numbers only')
				.max(6, 'Pincode should be maximum 6 numbers only'),
			WriteAbout: yup.string('value must be a string').nullable(),
			// uploadImage: isEditMode ? yup.array().nullable() : yup.array().min(1, 'Please add atleast one image').nullable(),
			uploadImage: yup.array().nullable(),
		}),
		enableReinitialize: true,
		onSubmit: saveEstablishmentDetails,
		// eslint-disable-next-line no-use-before-define
	})

	useEffect(() => {
		formik?.setFieldValue('uploadImage', establishmentFileResponse)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [establishmentFileResponse])

	const onSpecialityDelete = (index) => () => {
		const newArray = [...specialityChip]
		newArray.splice(index, 1)
		setSpecialityChip(newArray)
	}
	const [ammenitiesChip, setAmmenitiesChip] = useState([])

	const onAmmitiesDelete = (index) => () => {
		const newArray = [...ammenitiesChip]
		newArray.splice(index, 1)
		setAmmenitiesChip(newArray)
	}

	const [countryParams, setCountryParams] = useState({
		limit: 50,
		offset: 1,
		mastLookupType: 'CNT',
		search: '',
	})

	const onCountrySearch = _.debounce(
		(e, value) => {
			setCountryParams({
				...countryParams,
				search: value,
			})
		},
		[300]
	)

	const getCountryCode = useCallback(() => {
		setCountryLoading(true)
		const onSuccess = (res) => {
			setCountryLoading(false)
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				setCountryOptions(code)
			} else {
				setCountryOptions([])
			}
		}
		const onFailure = (err) => {
			setCountryLoading(false)
		}
		countryLookup.getCountryCode().then(onSuccess, onFailure)
	}, [])

	const [cityparsm, setCityparams] = useState({
		limit: 20,
		offset: 1,
		search: formik?.values?.mastState,
		mastCountryCode: '',
	})

	// useEffect(() => {
	// 	const onSuccess = (res) => {
	// 		if (res?.data?.status === 'success') {
	// 			setEstablishmentCityOptions(res?.data?.data)
	// 		} else {
	// 			setEstablishmentCityOptions([])
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		console.log('error', err)
	// 	}
	// 	establishmentApi.getEstablishmentCity(cityparsm?.search).then(onSuccess, onFailure)
	// }, [cityparsm])

	const getCityCode = useCallback(() => {
		setCityLoading(true)
		const onSuccess = (res) => {
			setCityLoading(false)
			if (res?.data?.status === 'success') {
				setEstablishmentCityOptions(res.data.data)
			} else {
				setEstablishmentCityOptions([])
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			setCityLoading(false)
		}
		lookupApi.getCountry('citiesStates', { ...cityparsm }, cancelExecutor).then(onSuccess, onFailure)
	}, [cityparsm, dispatch])

	// eslint-disable-next-line no-undef
	const onCitySearch = _.debounce(
		(e, value) => {
			setCityparams({
				...cityparsm,
				search: value,
			})
		},
		[100]
	)
	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (!_.isEmpty(res?.data?.data?.operationalHours)) {
				setHideForm(true)
			}
			dispatch(hideLoader())
			if (res?.data.status === 'success') {
				setEstablishmentDetails(res?.data.data)
				let initialCountryCode = _.find(countryOptions, (o) => o.country === res?.data?.data?.country || '')
				setCityparams((prevState) => ({
					...prevState,
					mastCountryCode: initialCountryCode?.countryCode || 'IN',
					search: res?.data?.data?.locality || '',
				}))
				formik?.setFieldValue('AddAmminities', res?.data?.data?.facilitiesList || [])
				// bodyEditableRef.current.innerHTML = res?.data?.data?.about ? decodeURIComponent(res?.data?.data?.about) : null
				formik?.setFieldValue('WriteAbout', res?.data?.data?.about ? decodeURIComponent(res?.data?.data?.about) : null)
				setUploadPhoto(res?.data?.data?.supportingDocUuid || [])
				setAmmenitiesChip(res?.data?.data?.facilitiesList || [])
				// secureLocalStorage.setItem('establishmentGetDetails', res?.data?.data || {})
				// dispatch(establishmentGetDetails(res?.data?.data))
				setSpecialityChip(res?.data?.data?.specialitesDTOS || [])
				formik?.setFieldValue('specialization', res?.data?.data?.specialization)
				formik?.setFieldValue('isShowSpecialization', true)
				if (res?.data?.data?.tentLatitude && res?.data?.data?.tentLongitude) {
					setCoordinates({ lat: Number(res?.data?.data?.tentLatitude), lng: Number(res?.data?.data?.tentLongitude) })
					setZoom(14)
				} else {
					if (navigator.geolocation) {
						navigator.geolocation.getCurrentPosition(showPosition)
					} else {
						alert('Geolocation is not supported by this browser.')
					}
				}
			} else {
				setEstablishmentDetails([])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		GetEstablishmentDetails.getEstablishmentDetails(mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, mastuuid])

	const detectLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition)
		} else {
			alert('Geolocation is not supported by this browser.')
		}
	}

	useEffect(() => {
		getCountryCode()
		getCityCode()
	}, [getCountryCode, getCityCode])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data.status === 'success') {
				setSpecialisationDetails(res?.data?.data || [])
			} else {
				setSpecialisationDetails([])
			}
		}
		const onFailure = (err) => {
			setSpecialisationDetails([])
		}
		GetEstablishmentDetails.getSpeciality(tentTypeId).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (uuid) {
			const onSuccess = (res) => {
				if (res?.data?.status === 'SUCCESS') {
					setFileCategoryList(res?.data?.data)
				} else {
					setFileCategoryList([])
				}
			}
			const onFailure = (err) => {
				console.log('Error', err)
			}

			fileUploadDetailsApi.getFileUploadDetails(uuid).then(onSuccess, onFailure)
		}
	}, [uuid])

	const displayEstablishmentImage = (acceptedFiles) => {
		dispatch(showLoader('uploading image. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setEstablishmentFileResponse((prevState) => [...prevState, ...(res?.data?.data?.fileMetaDataList || [])])
			} else {
				setEstablishmentFileResponse()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'CERTIFICATES')
		let categoryUuid = identityCategoryId?.uuid
		const formData = new FormData()
		_.map(acceptedFiles, (file) => formData.append('FILE', file))
		sendUploadFileApi.multiFileUpload(categoryUuid, mastuuid, uuid, formData).then(onSuccess, onFailure)
	}

	const [amminitiesDetails, setAmminitiesDetails] = useState([])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setAmminitiesDetails(res?.data?.data)
			} else {
				setAmminitiesDetails([])
			}
		}
		const onFailure = (err) => {
			setAmminitiesDetails([])
		}
		establishmentApi.getFacility().then(onSuccess, onFailure)
	}, [])

	const [amminitiesparsm, setAmminitiesParsm] = useState({
		limit: 20,
		offset: 1,
		search: '',
	})
	// eslint-disable-next-line no-undef
	const onAmminitiesSearch = _.debounce(
		(e, value) => {
			setAmminitiesParsm({
				...amminitiesparsm,
				search: value,
			})
		},
		[100]
	)

	const [slotbooking, setSlotBooking] = useState([])
	const [stateselect, setSelectState] = useState({
		Option1: false,
		Option2: false,
		Option3: false,
		Option4: false,
		Option5: false,
		Option6: false,
		Option7: false,
	})
	const { Option1, Option2, Option3, Option4, Option5, Option6, Option7 } = stateselect

	useEffect(() => {
		if (!_.isEmpty(establishmentDetails?.operationalHours || [])) {
			let operationalHours = {
				Option1: establishmentDetails?.operationalHours[0]?.upi || false,
				Option2: establishmentDetails?.operationalHours[0]?.creditOrDebitCard || false,
				Option3: establishmentDetails?.operationalHours[0]?.onlinePayment || false,
				Option4: establishmentDetails?.operationalHours[0]?.aidivaWallet || false,
				Option5: establishmentDetails?.operationalHours[0]?.atVenue || false,
				Option6: establishmentDetails?.operationalHours[0]?.cheque || false,
				Option7: establishmentDetails?.operationalHours[0]?.online || false,
			}
			setSelectState(operationalHours)
			let slots = []
			let selectedDay = []
			_.map(establishmentDetails?.operationalHours || [], (slot) => {
				let tempWeekDays = [
					{ id: 1, text: 'S', name: 'Sunday', isSelected: slot?.isSun },
					{ id: 2, text: 'M', name: 'Monday', isSelected: slot?.isMon },
					{ id: 3, text: 'T', name: 'Tuesday', isSelected: slot?.isTue },
					{ id: 4, text: 'w', name: 'wednesday', isSelected: slot?.isWed },
					{ id: 5, text: 'T', name: 'Thursday', isSelected: slot?.isThu },
					{ id: 6, text: 'F', name: 'Friday', isSelected: slot?.isFri },
					{ id: 7, text: 'S', name: 'Saturday', isSelected: slot?.isSat },
				]
				selectedDay = _.filter(tempWeekDays, (o) => _.isEqual(o?.isSelected, true))
				slots.push({
					operationAt: slot?.operationAt,
					closeAt: slot?.closesAt,
					paymentOptions: operationalHours,
					weekDays: tempWeekDays,
					selectDay: selectedDay,
					profileOpsHrsUuid: slot?.profileOpsHrsUuid,
				})
			})
			setSelectedDays(selectedDay)
			setSlotBooking(slots)
		}
	}, [establishmentDetails])

	const handleChange = (event, fieldName) => {
		setSelectState((prevState) => ({
			...prevState,
			[fieldName]: event.target.checked,
		}))
	}

	useEffect(() => {
		const optionCheck = Option1 || Option2 || Option3 || Option4 || Option5 || Option6 || Option7 ? stateselect : null
		slotFormik.setFieldValue('paymentOptions', optionCheck)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateselect])

	const handleItemClick = (name) => {
		const daysToggle = _.map(weekDays, (day) => {
			if (day?.name === name) {
				return { ...day, isSelected: !day?.isSelected }
			} else {
				return { ...day }
			}
		})
		setWeekDays(daysToggle)
	}

	useEffect(() => {
		const isSelected = _.findIndex(weekDays, (day) => day?.isSelected) > -1
		slotFormik?.setFieldValue('days', isSelected ? weekDays : null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [weekDays])

	const removeRow = (index, item) => {
		_.map(item?.selectDay || [], (day) => {
			const itemIndex = _.findIndex(selectedDays, { name: day })
			selectedDays.splice(itemIndex, 1)
		})
		const values = [...slotbooking]
		values.splice(index, 1)
		setSlotBooking(values)
	}
	const operationAtTime = (newValue) => {
		slotFormik.setFieldValue('operationAt', newValue)
	}

	//reuired validation
	// operationAt: yup.string().nullable().required('please select time'),
	// closeAt: yup.string().nullable().required('please select time'),
	// days: yup.array().nullable().min(1).required('Please Select atleast one day'),
	// paymentOptions: yup.object().nullable().required('Please select any payment method'),
	const slotFormik = useFormik({
		initialValues: {
			operationAt: formData?.operationAt || null,
			closeAt: formData?.closeAt || null,
			days: formData?.days || null,
			paymentOptions: formData?.paymentOptions || [],
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			operationAt: yup.string().nullable().required('please select time'),
			closeAt: yup.string().nullable().required('please select time'),
			days: yup.array().nullable().min(1).required('Please Select atleast one day'),
			paymentOptions: yup.object().nullable().required('Please select any payment method'),
		}),
		onSubmit: addSlot,
	})
	const closeTimeChange = (newValue) => {
		slotFormik.setFieldValue('closeAt', newValue)
	}

	useEffect(() => {
		dispatch(setEstablishmentListData(slotbooking))
	}, [dispatch, slotbooking])

	const deleteImage = (file) => {
		dispatch(showLoader('Deleting please wait...'))
		const onSuccess = (res) => {
			const imgs = _.filter(uploadPhoto, (img) => !_.isEqual(img?.docUuid, file?.docUuid))
			setUploadPhoto(imgs)
			establishmentDetails.supportingDocUuid = establishmentDetails.supportingDocUuid.filter((doc) => doc?.docUuid !== file?.docUuid)
			setEstablishDetailImgRemove(true)
			setEstablishmentDetails(establishmentDetails)
			dispatch(hideLoader())
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		fileUploadDetailsApi.deleteFile(file.docUuid).then(onSuccess).catch(onFailure)
	}

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				let initCode = _.find(code, { country: countryName })
				setMinMobileLen(initCode?.minimumLength)
				setMaxMobileLen(initCode?.mastLookupValue)
			}
		}
		const onFailure = (err) => {
			console.log(err)
		}

		countryLookup.getCountryCode().then(onSuccess, onFailure)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const displaySpecializationImage = (acceptedFiles, index) => {
		dispatch(showLoader('Uploading image please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				formik.setFieldValue(`specialization.${index}.originalFilePath`, res?.data?.data?.originalFilePath)
				formik.setFieldValue(`specialization.${index}.docDriveUuid`, res?.data?.data?.uuid)
			} else {
				formik.setFieldValue(`specialization.${index}.originalFilePath`, '')
				formik.setFieldValue(`specialization.${index}.docDriveUuid`, '')
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err.response.data, errorJson, 'Invalid File Name'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'PROFILE_MODULE')
		let categoryUuid = identityCategoryId?.uuid
		var formData2 = new FormData()
		formData2.append('FILE', acceptedFiles[0])
		sendUploadFileApi.sendUploadFile(mastuuid, uuid, categoryUuid, formData2).then(onSuccess, onFailure)
	}

	// const handleFormatClick = (format) => {
	// 	document.execCommand(format)
	// 	bodyEditableRef.current.focus()
	// 	// setContent(bodyEditableRef.current.innerHTML)
	// }

	// useEffect(() => {
	// 	if (bodyEditableRef?.current?.innerText === '' || bodyEditableRef?.current?.innerText === '\n') {
	// 		bodyEditableRef.current.innerHTML = ''
	// 	}
	// }, [bodyEditableRef?.current?.innerText, formik.values.WriteAbout])

	return (
		<>
			<div className={classes.establishmentDetailsMain}>
				<Grid container>
					<Grid container alignItems='center' spacing={2} className={classes.firstSection}>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Establishment Name<span className={classes.star}>*</span>
							</Typography>
							<TextField
								fullWidth
								size='small'
								type='text'
								color='secondary'
								id='profileEstablishmentName'
								name='profileEstablishmentName'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								value={formik?.values?.profileEstablishmentName}
								onChange={formik.handleChange}
								placeholder='Enter establishment name'
								error={formik.touched.profileEstablishmentName && formik.errors.profileEstablishmentName}
								helperText={
									formik.touched.profileEstablishmentName && formik.errors.profileEstablishmentName && formik.errors.profileEstablishmentName
								}
							/>
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Phone No<span className={classes.star}>*</span>
							</Typography>
							<TextField
								variant='outlined'
								id='profileEstablishmentPhoneNo'
								color='secondary'
								name='profileEstablishmentPhoneNo'
								value={formik.values.profileEstablishmentPhoneNo}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Enter mobile number'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								error={formik.touched.profileEstablishmentPhoneNo && formik.errors.profileEstablishmentPhoneNo}
								helperText={
									formik.touched.profileEstablishmentPhoneNo && formik.errors.profileEstablishmentPhoneNo && formik.errors.profileEstablishmentPhoneNo
								}
							/>
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Email Id<span className={classes.star}>*</span>
							</Typography>
							<TextField
								variant='outlined'
								type='text'
								color='secondary'
								id='profileEstablishmentEmail'
								name='profileEstablishmentEmail'
								value={formik?.values?.profileEstablishmentEmail}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Enter email id '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								error={formik.touched.profileEstablishmentEmail && formik.errors.profileEstablishmentEmail}
								helperText={
									formik.touched.profileEstablishmentEmail && formik.errors.profileEstablishmentEmail && formik.errors.profileEstablishmentEmail
								}
							/>
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Address line 1<span className={classes.star}>*</span>
							</Typography>
							<TextField
								variant='outlined'
								type='text'
								color='secondary'
								id='profileEstablishmentStreet'
								name='profileEstablishmentStreet'
								value={formik.values.profileEstablishmentStreet}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Street '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								error={formik.touched.profileEstablishmentStreet && formik.errors.profileEstablishmentStreet}
								helperText={
									formik.touched.profileEstablishmentStreet && formik.errors.profileEstablishmentStreet && formik.errors.profileEstablishmentStreet
								}
							/>
						</Grid>
					</Grid>
					<Grid container justifyContent='space-between' spacing={2} alignItems='center' className={classes.secondSection}>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Address line 2
							</Typography>
							<TextField
								variant='outlined'
								type='text'
								color='secondary'
								id='profileEstablishmentAddressTwo'
								name='profileEstablishmentAddressTwo'
								value={formik.values.profileEstablishmentAddressTwo}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Street '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input }}
								error={formik.touched.profileEstablishmentAddressTwo && formik.errors.profileEstablishmentAddressTwo}
								helperText={
									formik.touched.profileEstablishmentAddressTwo &&
									formik.errors.profileEstablishmentAddressTwo &&
									formik.errors.profileEstablishmentAddressTwo
								}
							/>
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Country<span className={classes.star}>*</span>
							</Typography>
							{/* <TextField
								variant='outlined'
								id='profileEstablishmentLocality'
								name='profileEstablishmentLocality'
								value={formik.values.profileEstablishmentLocality}
								onChange={formik.handleChange}
								color='secondary'
								fullWidth
								size='small'
								placeholder='Enter locality'
								InputProps={{ className: classes.input }}
								error={formik.touched.profileEstablishmentLocality && formik.errors.profileEstablishmentLocality}
								helperText={
									formik.touched.profileEstablishmentLocality &&
									formik.errors.profileEstablishmentLocality &&
									formik.errors.profileEstablishmentLocality
								}
							/> */}
							{/* <Autocomplete
								name='country'
								id='country'
								options={countryOptions}
								loading={countryLoading}
								getOptionLabel={(option) => option?.country || ''}
								value={formik?.values?.profileEstablishmentCountry}
								onChange={(e, value) => {
									formik?.setFieldValue('profileEstablishmentCountry', value)
									setCountryParams((prevState) => ({ ...prevState, search: value }))
									setCityparams((prevState) => ({ ...prevState, mastCountryCode: value?.countryCode }))
								}}
								getOptionSelected={(option, value) => {
									return option?.country === value?.country
								}}
								onInputChange={onCountrySearch}
								disabled={true}
								renderInput={(params) => (
									<TextField
										{...params}
										error={formik.touched.profileEstablishmentCountry && formik.errors.profileEstablishmentCountry}
										helperText={
											formik.touched.profileEstablishmentCountry &&
											formik.errors.profileEstablishmentCountry &&
											formik.errors.profileEstablishmentCountry
										}
										variant='outlined'
										color='secondary'
									/>
								)}
							/> */}
							<TextField
								variant='outlined'
								type='text'
								color='secondary'
								id='profileEstablishmentCountry'
								name='profileEstablishmentCountry'
								value={formik.values.profileEstablishmentCountry}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Street '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								error={formik.touched.profileEstablishmentCountry && formik.errors.profileEstablishmentCountry}
								helperText={
									formik.touched.profileEstablishmentCountry && formik.errors.profileEstablishmentCountry && formik.errors.profileEstablishmentCountry
								}
							/>
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								State<span className={classes.star}>*</span>
							</Typography>
							<TextField
								variant='outlined'
								type='text'
								color='secondary'
								id='profileEstablishmentState'
								name='profileEstablishmentState'
								value={formik.values.profileEstablishmentState}
								onChange={formik.handleChange}
								fullWidth
								size='small'
								placeholder='Street '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input, readOnly: true }}
								error={formik.touched.profileEstablishmentState && formik.errors.profileEstablishmentState}
								helperText={
									formik.touched.profileEstablishmentState && formik.errors.profileEstablishmentState && formik.errors.profileEstablishmentState
								}
							/>
							{/* <Autocomplete
								name='profileEstablishmentState'
								id='profileEstablishmentState'
								value={formik?.values?.profileEstablishmentState}
								options={establishmentCityOptions}
								getOptionLabel={(option) => option.mastCities || ''}
								onChange={(e, value) => {
									formik?.setFieldValue('profileEstablishmentState', value?.mastCities)
									setCityparams((prevState) => ({ ...prevState, search: value }))
								}}
								onInputChange={onCitySearch}
								placeholder='State'
								error={formik.touched.profileEstablishmentState && formik.errors.profileEstablishmentState}
								helperText={
									formik.touched.profileEstablishmentState && formik.errors.profileEstablishmentState && formik.errors.profileEstablishmentState
								}
								disabled={true}
								renderInput={(params) => (
									<TextField
										{...params}
										error={formik.touched.profileEstablishmentCountry && formik.errors.profileEstablishmentCountry}
										helperText={
											formik.touched.profileEstablishmentCountry &&
											formik.errors.profileEstablishmentCountry &&
											formik.errors.profileEstablishmentCountry
										}
										variant='outlined'
										color='secondary'
									/>
								)}
							/> */}
						</Grid>
						<Grid item lg={3} sm={12}>
							<Typography pb={1} className={classes.paymentOptions}>
								Pin Code
							</Typography>
							<TextField
								variant='outlined'
								type='string'
								id='profileEstablishmentPinCode'
								value={formik.values.profileEstablishmentPinCode}
								onChange={formik.handleChange}
								color='secondary'
								name='profileEstablishmentPinCode'
								fullWidth
								size='small'
								inputProps={{ maxLength: 6 }}
								placeholder='Enter pincode '
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ className: classes.input }}
								error={formik.touched.profileEstablishmentPinCode && formik.errors.profileEstablishmentPinCode}
								helperText={
									formik.touched.profileEstablishmentPinCode && formik.errors.profileEstablishmentPinCode && formik.errors.profileEstablishmentPinCode
								}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<div className={classes.mapPart}>
							<Grid item lg={6} sm={12}>
								<div className={classes.mapPartOne}>
									<Typography className={classes.locationText} onClick={detectLocation} variant='body1'>
										<MyLocationIcon fontSize='small' /> Detect current location
									</Typography>
									<div className={classes.section2FullWidth}>
										<div className={classes.location}>
											<MyLocation
												tentLatitude={tentLatitude}
												tentLongitude={tentLongitude}
												center={coordinates}
												zoom={zoom}
												onDragEnded={onDragEnd}
											/>
										</div>
									</div>
								</div>
							</Grid>
							<Grid item lg={6} sm={12}>
								{/* <SlotBook
									slotbooking={slotbooking}
									stateselect={stateselect}
									handleChange={handleChange}
									closeTimeChange={closeTimeChange}
									operationTimeChange={operationAtTime}
									weekDays={weekDays}
									hideForm={hideForm}
									setHideForm={setHideForm}
									onClickHideForm={onClickHideForm}
									selectedDays={selectedDays}
									handleItemClick={handleItemClick}
									removeRow={removeRow}
									slotFormik={slotFormik}
									formRef={submitType}
								/> */}
							</Grid>
						</div>
					</Grid>
					<Grid container pt={2}>
						<Grid item lg={12}>
							<div className={classes.uploadPart}>
								<Typography pt={1} className={classes.paymentOptions}>
									Facility photos:
								</Typography>
								{!_.isEmpty(uploadPhoto) && (
									<Grid item lg={12}>
										<Box
											mb={1}
											mt={1}
											sx={{
												'& > :not(:last-child)': { mr: 1 },
												'& > *': { mr: 2 },
											}}
											style={{ display: 'flex', flexWrap: 'wrap' }}
										>
											{uploadPhoto.map((v, idx) => (
												<div className={classes.imgContainer}>
													<div className={classes.deletIcon} onClick={() => deleteImage(v)}>
														<DeleteOutlineIcon />
													</div>
													<Avatar variant='square' src={getImgUrl(v?.docUuid, token)} className={classes.mainImg} />
												</div>
											))}
										</Box>
									</Grid>
								)}
								<div className={classes.uploadDots}>
									<div className={classes.uploadImage}>
										<CompDropZone
											imgResponse={establishmentFileResponse}
											setImage={setEstablishmentFileResponse}
											displayUploadImage={displayEstablishmentImage}
											imageTextOne='Drag Your Image'
											isMulti={true}
										/>
									</div>
								</div>
							</div>
							<Typography>Upload establishment image</Typography>
						</Grid>
					</Grid>
					<Grid container pt={2}></Grid>
				</Grid>
				<Grid container md={12} className={classes.addSpeciality}>
					<Grid item lg={12} sm={12}>
						<div className={classes.addServiceAdd}>
							<Typography pt={1} pb={1} className={classes.paymentOptions}>
								Speciality:
							</Typography>

							{!_.isEmpty(specialityChip) && (
								<Grid item lg={12}>
									<Box
										mb={1}
										mt={1}
										sx={{
											'& > :not(:last-child)': { mr: 1, background: 'antiquewhite !important' },
											'& > *': { mr: 2, background: 'antiquewhite !important' },
										}}
										style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}
									>
										{!_.isEmpty(specialityChip) && specialityChip.map((v, index) => <Chip key={index} label={v.specialityName} />)}
										{/* specialityChip.map((v, index) => <Chip key={index} label={v.specialityName} onDelete={onSpecialityDelete(index)} />)} */}
									</Box>
								</Grid>
							)}
							{/* <Box>
								<Autocomplete
									multiple
									id='SearchandAdd'
									name='SearchandAdd'
									options={specialisationDetails}
									getOptionLabel={(option) => option?.specialityName || ''}
									getOptionSelected={(option, value) => option?.specialityName === value?.specialityName}
									value={specialityChip}
									filterSelectedOptions={true}
									disableCloseOnSelect={true}
									fullWidth
									size='small'
									noOptionsText='No options'
									className={classes.searchCategoriey}
									onChange={(e, value) => {
										setSpecialityChip((prevState) => [...value])
										formik.setFieldValue('SearchandAdd', value)
									}}
									renderTags={() => null}
									renderInput={(params) => (
										<TextField
											{...params}
											color='secondary'
											error={specialityChip.length > 1 ? null : formik.touched.SearchandAdd && formik.errors.SearchandAdd}
											helperText={
												specialityChip.length > 1 ? null : formik.touched.SearchandAdd && formik.errors.SearchandAdd && formik.errors.SearchandAdd
											}
											variant='outlined'
											placeholder='Add speciality'
										/>
									)}
								/>
							</Box> */}
						</div>
					</Grid>
				</Grid>
				{!_.isEmpty(formik?.values?.specialization) && (
					<Grid container md={12} className={classes.addSpeciality} mt={2} mb={2}>
						<Grid item lg={12} sm={12}>
							<div className={classes.addServiceAdd}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<Typography pt={1} pb={1} className={classes.paymentOptions}>
										Specialization:
									</Typography>
									{/* <FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik?.values?.isShowSpecialization}
													onChange={(event) => formik?.setFieldValue('isShowSpecialization', event.target.checked)}
													sx={{
														'&.Mui-checked': {
															color: '#0062DD',
														},
													}}
												/>
											}
											label='Show specialization in microsite'
										/>
									</FormGroup> */}
								</div>

								{!_.isEmpty(formik?.values?.specialization) && (
									<>
										<Grid item lg={12}>
											<Box mb={1} mt={1} style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
												{!_.isEmpty(formik?.values?.specialization) &&
													formik?.values?.specialization.map((v, index) => (
														<>
															<Chip
																key={index}
																label={v.specializationName}
																onClick={() => setSelectedSpecialization(index)}
																sx={{
																	mr: 1,
																	backgroundColor: selectedSpecialization === index ? '#0062DD !important' : 'antiquewhite !important',
																	color: selectedSpecialization === index ? '#fff' : '#000',
																	'&:hover': {
																		backgroundColor: selectedSpecialization === index ? '#0056b3 !important' : '#e6e6e6 !important',
																		cursor: 'pointer',
																	},
																}}
															/>
														</>
													))}
											</Box>
										</Grid>
										{!_.isEmpty(formik?.values?.specialization) &&
											formik?.values?.specialization.map((v, index) => (
												<>
													<div
														className={classes.specializationTextEditor}
														style={{ display: _.isEqual(selectedSpecialization, index) ? 'flex' : 'none' }}
													>
														<DropZone
															imgResponse={formik?.values?.specialization[index]?.originalFilePath}
															setEstablishmentFileResponse={() => {
																formik.setFieldValue(`specialization.${index}.originalFilePath`, '')
																formik.setFieldValue(`specialization.${index}.docDriveUuid`, '')
															}}
															displayUploadImage={(files) => displaySpecializationImage(files, index)}
														/>
														<RichTextEditor
															value={formik?.values?.specialization[index]?.specializationDesc}
															image={true}
															onChange={(content) => {
																formik.setFieldValue(`specialization.${index}.specializationDesc`, content)
															}}
														/>
													</div>
												</>
											))}
									</>
								)}
								{/* <Box>
								<Autocomplete
									multiple
									id='SearchandAdd'
									name='SearchandAdd'
									options={specialisationDetails}
									getOptionLabel={(option) => option?.specialityName || ''}
									getOptionSelected={(option, value) => option?.specialityName === value?.specialityName}
									value={specialityChip}
									filterSelectedOptions={true}
									disableCloseOnSelect={true}
									fullWidth
									size='small'
									noOptionsText='No options'
									className={classes.searchCategoriey}
									onChange={(e, value) => {
										setSpecialityChip((prevState) => [...value])
										formik.setFieldValue('SearchandAdd', value)
									}}
									renderTags={() => null}
									renderInput={(params) => (
										<TextField
											{...params}
											color='secondary'
											error={specialityChip.length > 1 ? null : formik.touched.SearchandAdd && formik.errors.SearchandAdd}
											helperText={
												specialityChip.length > 1 ? null : formik.touched.SearchandAdd && formik.errors.SearchandAdd && formik.errors.SearchandAdd
											}
											variant='outlined'
											placeholder='Add speciality'
										/>
									)}
								/>
							</Box> */}
							</div>
						</Grid>
					</Grid>
				)}
				<Grid container md={12} className={classes.addSpeciality}>
					{/* <Typography pt={1} pb={1} className={classes.paymentOptions}>
						Ammenities:
					</Typography>{' '}
					{!_.isEmpty(ammenitiesChip) ? (
						<Grid item lg={12}>
							<Box
								mb={1}
								mt={1}
								sx={{
									'& > :not(:last-child)': { mr: 1, background: 'antiquewhite !important' },
									'& > *': { mr: 2, background: 'antiquewhite !important' },
								}}
								style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}
							>
								{ammenitiesChip.map((v, index) => (
									<Chip key={index} label={v.mastFacilityName || v.facilitesName} onDelete={onAmmitiesDelete(index)} />
								))}
							</Box>
						</Grid>
					) : (
						<Grid item lg={12}></Grid>
					)}
					<Grid item lg={4} sm={12}>
						<div className={classes.uploadPart}>
							<Box>
								<Autocomplete
									multiple
									name='AddAmminities'
									options={amminitiesDetails}
									getOptionLabel={(option) => option.mastFacilityName || ''}
									value={ammenitiesChip}
									filterSelectedOptions={true}
									disableCloseOnSelect={true}
									className={classes.searchCategoriey}
									fullWidth
									size='small'
									noOptionsText='No options'
									onChange={(e, value) => {
										setAmmenitiesChip((prevState) => [...value])
										formik?.setFieldValue('AddAmminities', value)
									}}
									onInputChange={onAmminitiesSearch}
									renderTags={() => null}
									renderInput={(params) => (
										<TextField
											{...params}
											error={formik.touched.AddAmminities && formik.errors.AddAmminities}
											helperText={formik.touched.AddAmminities && formik.errors.AddAmminities && formik.errors.AddAmminities}
											variant='outlined'
											color='secondary'
											placeholder='Add amminities'
											style={{ paddingTop: '4px' }}
										/>
									)}
								/>
							</Box>
						</div>
					</Grid> */}
					<Grid container pt={1} className={classes.ammenitiesTextArea}>
						<Grid item lg={12} sm={12}>
							<div className={classes.uploadPart}>
								<div className={classes.aboutTitle}>
									<Typography pt={0} className={classes.paymentOptions}>
										About:
									</Typography>
									{/* <div className=''>
										<Tooltip title='Bold'>
											<IconButton onClick={() => handleFormatClick('bold')}>
												<FormatBold style={{ color: '#0062DD' }} />
											</IconButton>
										</Tooltip>
										<Tooltip title='Italic'>
											<IconButton onClick={() => handleFormatClick('italic')}>
												<FormatItalic style={{ color: '#0062DD' }} />
											</IconButton>
										</Tooltip>
									</div> */}
								</div>
								<div>
									{/* <TextField
										id='WriteAbout'
										name='WriteAbout'
										variant='outlined'
										placeholder='Add description here...'
										multiline
										value={formik?.values?.WriteAbout}
										onChange={formik?.handleChange}
										fullWidth
										focused={false}
										size='small'
										rows={5}
										rowsMax={10}
										color='secondary'
										error={formik.touched.WriteAbout && formik.errors.WriteAbout}
										helperText={formik.touched.WriteAbout && formik.errors.WriteAbout && formik.errors.WriteAbout}
									/> */}
									<div className={classes.bodyField}>
										<TextField
											multiline
											color='secondary'
											fullWidth
											variant='outlined'
											label='Multiline Text'
											className={classes.variableField}
											onFocus={true}
											onBlur={true}
											// value={formik?.values?.WriteAbout}
										/>
										{/* <div
											ref={bodyEditableRef}
											contentEditable={true}
											style={{
												border: '1px solid #ccc',
												minHeight: '100px',
												padding: '8px',
												lineHeight: '1.6',
												overflowY: 'auto',
												borderRadius: 4,
												outline: 'none',
												width: '100%',
											}}
											tabIndex={1}
											onInput={() => {
												const newContent = bodyEditableRef.current.innerHTML
												if (!bodyEditableRef?.current?.innerText === '') {
													formik?.setFieldValue('WriteAbout', newContent)
												} else {
													formik?.setFieldValue('WriteAbout', null)
												}
											}}
										></div> */}
										<RichTextEditor
											value={formik?.values?.WriteAbout}
											image={true}
											onChange={(content) => formik?.setFieldValue('WriteAbout', content)}
										/>
										<FormHelperText className={classes.errorMessage}>
											{formik.touched.WriteAbout && formik.errors.WriteAbout && formik.errors.WriteAbout}
										</FormHelperText>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<div className={classes.buttonMain}>
				<ButtonComp onClick={() => moveBackTab()}>Back</ButtonComp>
				<ButtonComp
					onClick={(e) => {
						// submitType.current = 'formSubmission'
						formik.handleSubmit(e)
						// slotFormik.handleSubmit(e)
					}}
				>
					Save & Next
				</ButtonComp>
			</div>
		</>
	)
}
export default EstablishmentDetails
