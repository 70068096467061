import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Chip, Grid, Skeleton, Stack, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MenuSelectionApi from 'services/ProfileSetup/MenuSelection/api'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import CustomBtn from 'app/sharedComponents/Button'
import SuccessModal from '../SuccessModal'
import { DragHandle } from '@mui/icons-material'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const useStyles = makeStyles((theme) => ({
	checked: {
		fontWeight: 500,
		border: '0.5px solid #0062DD',
		// background: '#0062DD',
		'& .MuiSvgIcon-root': {
			color: '#0062DD',
		},
	},
	selectionStack: {
		'& .MuiTypography-subtitle1': {
			cursor: 'pointer',
			color: '#0062DD',
			fontWeight: 500,
			textDecoration: 'underline',
		},
	},
	chipContainer: {
		// display: 'grid',
		// gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
		// gap: 8,
		// [theme.breakpoints.down('md')]: {
		// 	gridTemplateColumns: '1fr 1fr 1fr',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	gridTemplateColumns: '1fr 1fr',
		// },
	},
}))

const ItemTypes = {
	CARD: 'card', // Change this to match your logic
}

const MenuSelection = ({ history, moveBackTab }) => {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const dispatch = useDispatch()
	const { mastuuid } = state
	const [menuData, setMenuData] = useState([])
	const [editMenuData, setEditMenuData] = useState([])
	const [selectedChips, setSelectedChips] = useState(editMenuData ? editMenuData?.map((item) => item?.micrositeMenuUuid) : [])
	const [loading, setLoading] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)

	const handleChipClick = (micrositeMenuUuid, index) => {
		setSelectedChips((prevSelectedChips) => {
			if (prevSelectedChips.includes(micrositeMenuUuid)) {
				return prevSelectedChips.filter((uuid) => uuid !== micrositeMenuUuid)
			} else {
				const updatedChips = [...prevSelectedChips]
				updatedChips.splice(index, 0, micrositeMenuUuid)
				return updatedChips
			}
		})
	}

	const handleSelectAll = () => {
		setSelectedChips(menuData?.map((item) => item.micrositeMenuUuid))
		setEditMenuData(menuData)
	}

	const handleDeselectAll = () => {
		setSelectedChips([])
		setEditMenuData([])
	}

	const getMenuData = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setMenuData(res?.data?.data)
		}
		const onFailure = (err) => {
			setLoading(false)
			setMenuData([])
		}
		MenuSelectionApi.getMicrositeMenuData(mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}, [mastuuid, profileHeaderUuid])

	const postMenuData = () => {
		const orderedSelectedChips = menuData.filter((item) => selectedChips.includes(item.micrositeMenuUuid)).map((item) => item.micrositeMenuUuid)

		const selectedChipsWithNames = orderedSelectedChips.map((uuid) => {
			const matchedMenuItem = menuData.find((menuItem) => menuItem.micrositeMenuUuid === uuid)
			return matchedMenuItem?.micrositeMenuName || 'Unknown Name'
		})

		dispatch(showLoader('Loading Please Wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
			setModalOpen(true)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		MenuSelectionApi.postMicrositeMenuData(orderedSelectedChips, mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}

	const getAssignedMicrositeMenu = useCallback(() => {
		const onSuccess = (res) => {
			setEditMenuData(res?.data?.data)
			setSelectedChips(res?.data?.data.map((item) => item.micrositeMenuUuid))
		}
		const onFailure = (err) => {
			setEditMenuData([])
			setSelectedChips([])
		}
		MenuSelectionApi.getAssignedMicrositeMenuData(mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}, [mastuuid, profileHeaderUuid])

	useEffect(() => {
		getMenuData()
		getAssignedMicrositeMenu()
	}, [getMenuData, getAssignedMicrositeMenu])

	const closeSuccessModal = () => {
		setModalOpen(false)
		history.push(`/profile`)
	}

	const Card = ({ id, item, index, moveCard }) => {
		const ref = useRef(null)
		const [{ handlerId }, drop] = useDrop({
			accept: ItemTypes.CARD,
			collect(monitor) {
				return {
					handlerId: monitor.getHandlerId(),
				}
			},
			drop(droppedItem, monitor) {
				if (!monitor.didDrop()) {
					const dragIndex = droppedItem?.index
					const hoverIndex = index

					// Check if dragging over the same card
					if (dragIndex !== hoverIndex) {
						moveCard(dragIndex, hoverIndex)
					}
				}
			},
		})
		const [{ isDragging }, drag] = useDrag({
			type: ItemTypes.CARD,
			item: () => {
				return { id, index }
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		})
		const opacity = isDragging ? 0 : 1
		drag(drop(ref))

		return (
			<div ref={ref} data-handler-id={handlerId}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '16px',
						opacity: opacity,
						cursor: 'move',
					}}
				>
					<DragHandle />
					<Chip
						key={index}
						sx={{ display: 'flex', justifyContent: 'flex-start', width: 400, marginBlock: '12px' }}
						label={item?.micrositeMenuName}
						icon={selectedChips.includes(item.micrositeMenuUuid) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
						variant='outlined'
						onClick={() => handleChipClick(item.micrositeMenuUuid, index)}
						className={selectedChips.includes(item.micrositeMenuUuid) ? classes.checked : ''}
					/>
				</div>
			</div>
		)
	}

	const Container = () => {
		// eslint-disable-next-line no-lone-blocks
		{
			const moveCard = useCallback((dragIndex, hoverIndex) => {
				setMenuData((prevMenu) => {
					const updatedMenus = [...prevMenu]
					const [draggedItem] = updatedMenus?.splice(dragIndex, 1)
					updatedMenus?.splice(hoverIndex, 0, draggedItem)
					return updatedMenus
				})
				setEditMenuData((prevMenu) => {
					const updatedMenus = [...prevMenu]
					const [draggedItem] = updatedMenus?.splice(dragIndex, 1)
					updatedMenus?.splice(hoverIndex, 0, draggedItem)

					// const updatedSelectedChips = updatedMenus
					// 	.filter((menu) => selectedChips?.includes(menu?.micrositeMenuUuid))
					// 	.map((menu) => menu?.micrositeMenuUuid)

					// setSelectedChips(updatedSelectedChips)

					return updatedMenus
				})
			}, [])
			const renderCard = useCallback((item, index) => {
				return <Card key={item.id} index={index} id={item.id} item={item} moveCard={moveCard} />
			}, [])
			return (
				<>
					<div>{menuData.map((item, i) => renderCard(item, i))}</div>
				</>
			)
		}
	}

	return (
		<div>
			{/* <SuccessModal open={modalOpen} closeSuccessModal={closeSuccessModal} /> */}
			{_.isEqual(loading, true) ? (
				<>
					<Stack direction={'row'} gap={2} className={classes.selectionStack} marginBottom={1}>
						<Skeleton width={120} height={30}></Skeleton>
						<Skeleton width={120} height={30}></Skeleton>
					</Stack>
					<div className={classes.chipContainer}>
						{Array.from({ length: 10 }).map((_, idx) => (
							<Skeleton variant='rounded' key={idx} width={400} sx={{ borderRadius: 30, marginBlock: '12px' }} height={40}></Skeleton>
						))}
					</div>
				</>
			) : (
				<>
					<Stack direction={'row'} gap={2} className={classes.selectionStack} marginBottom={1}>
						<Typography onClick={handleSelectAll} variant='subtitle1'>
							Select All
						</Typography>
						<Typography onClick={handleDeselectAll} variant='subtitle1'>
							Unselect All
						</Typography>
					</Stack>
					<DndProvider backend={HTML5Backend}>
						<Container />
					</DndProvider>
					<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', gap: 24, marginTop: 18 }}>
						<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
						<CustomBtn onClick={() => postMenuData()} disabled={selectedChips.length === 0}>
							Save
						</CustomBtn>
					</Grid>
				</>
			)}
		</div>
	)
}

export default MenuSelection
