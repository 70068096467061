import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	upload: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#efefef',
		cursor: 'pointer',
		textAlign: 'center',
		width: 220,
		height: 150,
		// height: 150,
		'& .MuiSvgIcon-root': {
			fontSize: '6rem',
		},
	},
	fileName: {
		display: 'flex',
		gap: 3,
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: '3rem',
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
}))

export default useStyles
