/* eslint-disable prettier/prettier */
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import Form from './loginForm'
import LoginSwip from '../../SwiperLogin'
import LyfngoFlashLogo from '../../../../images/logos/LyfngoFlash.png'
import _ from 'lodash'
import { Typography } from '@mui/material'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
const Root = styled('div')(({ theme }) => ({
	// background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,
	'& .card-height': {
		height: '70vh',
		boxShadow: '0px 1px 6px 2px #0000001A',
	},
	'& .Login-leftSection': {
		maxWidth: 412,
	},

	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .cardLogoContent': {
		width: '100%',
	},
}))
const Swiper = styled('div')(({ theme }) => ({
	maxWidth: 1000,
	background: '#fff',
	[theme.breakpoints.down('xs')]: {
		display: 'none',
	},
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}))
const Footer = styled('div')(({ theme }) => ({
	position: 'relative',
	top: 48,
	'& .MuiTypography-body1': {
		color: '#000',
		fontWeight: 600,
	},
}))

export function getExplore() {
	return `${
		(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com')) ||
		(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.rigelsoft.online')) ||
		(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com'))
	}`
}
function LoginOld({ history }) {
	const location = window?.location?.pathname
	useEffect(() => {
		const token = secureLocalStorage.getItem('AidiBearer')
		if (token) {
			history?.push('/dashboard')
		}
	}, [history])

	const onClick = () => {
		return `${
			(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com/start-free-trial')) ||
			(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.rigelsoft.online/start-free-trial')) ||
			(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com/start-free-trial'))
		}`
	}

	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				<div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='card-height flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden'
				>
					<Card className='Login-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0 ' square>
						<CardContent className='cardContent flex flex-col items-center justify-center w-full p-0 max-w-320'>
							<div className='cardLogoContent' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-center mb-20' style={{ cursor: 'pointer' }} onClick={getExplore}>
									<img className='logo-icon' src={LyfngoFlashLogo} alt='logo' width='120' height='72' />
								</div>
							</div>
							<div className='mb-20 w-full'>
								<Typography variant='body1' style={{ color: '#000', fontSize: 16, fontWeight: 500 }}>
									Sign in
								</Typography>
								<Typography variant='body1' style={{ color: '#000', fontSize: 14 }}>
									to access your <span style={{ color: '#0062DD', fontWeight: 600 }}>LYFnGO</span> account
								</Typography>
							</div>
							<Form />
						</CardContent>
						<div className='flex flex-col items-center justify-center pb-32 mt-10'>
							<Typography
								variant='body1'
								onClick={onClick}
								style={{ color: '#0062DD', fontWeight: 500, textDecoration: 'underline', cursor: 'pointer' }}
							>
								Get <span style={{ fontWeight: 600 }}>14-days</span> free trial now
							</Typography>
						</div>
					</Card>
					<Swiper>
						<LoginSwip location={location} styleStatus={true} />
					</Swiper>
				</div>
				<Footer className='footer-Container'>
					<Typography variant='body1'>© {new Date().getFullYear()} LYFnGO, All Rights Reserved.</Typography>
				</Footer>
			</Root>
		</>
	)
}

export default LoginOld
