import { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Popover, IconButton, Badge, Typography, Tooltip, Grid, Box, Avatar } from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import NotificationsAPI from 'services/HeaderPushNotification/api.js'
import _ from 'lodash'
import moment from 'moment'
import ReconnectingWebSocket from 'reconnecting-websocket'
import History from '@history/@history'
import secureLocalStorage from 'react-secure-storage'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import themesConfig from 'app/fuse-configs/themesConfig'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
	rootPopper: {
		'& .MuiPopover-paper': {
			width: 400,
		},
	},
	boxContainer: {
		paddingInline: 6,
		maxHeight: '68vh',
		overflowY: 'scroll',
	},
	gridContainer: {
		'& .MuiAvatar-root': {
			borderRadius: 0,
		},
		borderRadius: 8,
		padding: 8,
		marginBlock: 8,
		cursor: 'pointer',
	},
	unSeenBgColor: {
		background: '#EBF5FB',
	},
	notiClear: {
		textAlign: 'right',
		'& .MuiSvgIcon-root': {
			color: '#E55C26',
		},
		'& .MuiTypography-body1': {
			fontSize: 12,
			color: 'rgba(0, 0, 0, 0.50)',
			fontWeight: 500,
		},
	},
	notiMsg: {
		fontWeight: 500,
		fontSize: 14,
		textAlign: 'justify',
		'& span': {
			color: '#0062DD',
		},
	},
	clrAllBox: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	clrAll: {
		color: '#0062DD',
		fontSize: 14,
		fontWeight: 500,
		textAlign: 'right',
		textDecoration: 'underline',
		cursor: 'pointer',
		paddingInline: 10,
	},
	badge: {
		'& .MuiBadge-colorSecondary': {
			background: themesConfig.lyfngo.palette.secondary.main,
			color: themesConfig.lyfngo.palette.light.main,
		},
	},
	addBill: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	noNoti: {
		textAlign: 'center',
		paddingBlock: 20,
	},
	iconBtn: {
		'&:hover .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
		'&:hover': {
			background: themesConfig.lyfngo.palette.light.main,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 'lighter',
			'&:hover': {
				color: themesConfig.lyfngo.palette.primary.main,
			},
		},
	},
}))

var socket

function HeaderNotification({ playAudio }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const { mastuuid, uuid, BearerToken } = useSelector((state) => state.Authentication)
	const [notificationsList, setNotificationsList] = useState([])
	const [userMenu, setUserMenu] = useState(null)

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget)
	}

	const userMenuClose = () => {
		setUserMenu(null)
	}

	// Getting the notificationsList initially with an GET Api
	const getNotification = () => {
		NotificationsAPI.getNotifications({ tentUserUuid: uuid }).then(
			(res) => {
				setNotificationsList(res?.data?.data)
			},
			() => {}
		)
	}

	useEffect(() => {
		if (BearerToken) {
			getNotification()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, uuid])

	const handleMessageClick = (data) => {
		// Making it as seen
		let tempArr = []
		notificationsList?.map((item) => {
			if (item?.notificationId === data?.notificationId) {
				return tempArr?.push({ ...item, isSeen: true })
			} else {
				return tempArr?.push(item)
			}
		})
		setNotificationsList(tempArr)

		// Navigating to Pages:
		if (_.isEqual(data?.pushNotificationType, 'appointmentAdd') || _.isEqual(data?.pushNotificationType, 'appointmentReschedule')) {
			setUserMenu(null)
			secureLocalStorage.setItem('custUuid', `${data?.redirectData?.clientId}`)
			dispatch(customer_Uuid(`${data?.redirectData?.clientId}`))
			History.push({
				pathname: `/clientinfo/${data?.redirectData?.clientId}?tabId=appointment`,
			})
		} else if (_.isEqual(data?.pushNotificationType, 'Pharmacist')) {
			History.push({
				pathname: `/pharma`,
				state: { pushNotificationType: 'Pharmacist', status: 'initial', ...data?.redirectData },
			})
		} else if (_.isEqual(data?.pushNotificationType, 'consult')) {
			History.push({
				pathname: `/chats`,
			})
		} else if (_.isEqual(data?.pushNotificationType, 'Pharma')) {
			History.push({
				pathname: `/pharma`,
				state: { pushNotificationType: 'Pharma', status: 'initial', ...data?.redirectData },
			})
		} else if (_.isEqual(data?.pushNotificationType, 'Quick sale')) {
			History.push({
				pathname: `/quicksale`,
			})
		} else if (_.isEqual(data?.pushNotificationType, 'healthFileExtraction')) {
			setUserMenu(null)
			secureLocalStorage.setItem('custUuid', `${data?.redirectData?.cust_uuid}`)
			dispatch(customer_Uuid(`${data?.redirectData?.cust_uuid}`))
			History.push({
				pathname: `/clientinfo/${data?.redirectData?.cust_uuid}?tabId=healthboard`,
				state: { pushNotificationType: 'healthFileExtraction', data: data?.redirectData },
			})
		}

		userMenuClose()

		// Updating in the API
		let body = { tentUuid: mastuuid, tentUserUuid: uuid, notifcationID: data?.notificationId }
		NotificationsAPI.readNotification(body).then(
			(res) => {},
			() => {}
		)
	}

	const clearSingleNotification = (data) => {
		let body = { tentUuid: mastuuid, tentUserUuid: uuid, notifcationID: data?.notificationId }
		let updatedNotificationList = notificationsList?.filter((item) => item?.notificationId !== data?.notificationId)
		setNotificationsList(updatedNotificationList)
		NotificationsAPI.clearOneNotification(body).then(
			() => {},
			() => {}
		)
	}

	const clearAllNotification = () => {
		let body = { tentUuid: mastuuid, tentUserUuid: uuid }
		setNotificationsList([])
		NotificationsAPI.clearAllNotifications(body).then(
			() => {},
			() => {}
		)
	}

	// Connecting the socket
	useEffect(() => {
		// closing the existing connection
		// !_.isEmpty(socket) && socket.close()
		if (!_.isEmpty(mastuuid) && !_.isEmpty(uuid) && !_.isEmpty(BearerToken)) {
			socket = new ReconnectingWebSocket(`${process.env.REACT_APP_PUSH_NOTIFICATION_SOCKET + '/' + mastuuid + '/' + uuid}`)
			socket.debug = true
			socket.timeoutInterval = 3000
			socket.maxRetries = 4
			socket.onopen = (event) => {
				if (event?.currentTarget?.readyState === 1) {
					// console.log('Websocket connected')
				} else {
					socket.onerror = function (error) {
						socket.onclose = (event) => {
							// console.log('The connection has been closed successfully.')
						}
					}
					socket.onclose = (event) => {
						// console.log('The connection has been closed successfully.')
					}
					window.alert('waiting for connection')
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, uuid])

	const showBottomNotification = (textMsg, data) => {
		enqueueSnackbar(textMsg, {
			variant: 'default',
			action: (snackbarId) => (
				<>
					<Typography
						style={{ color: '#5151ff', fontWeight: 600, marginRight: 20 }}
						sx={{
							'&:hover': {
								textDecoration: 'underline',
							},
						}}
						onClick={() => {
							handleMessageClick(data)
							closeSnackbar(snackbarId)
						}}
					>
						Click Here
					</Typography>

					<IconButton onClick={() => closeSnackbar(snackbarId)}>
						<CloseIcon style={{ color: '#fff' }} />
					</IconButton>
				</>
			),
		})
	}

	//Socket receving messages
	useEffect(() => {
		if (BearerToken) {
			socket.onmessage = (event) => {
				const newMessage = JSON.parse(event.data)
				setNotificationsList((prevList) => [newMessage, ...prevList])
				// Bottom notification with sound
				let { pushNotificationType, notificationMessage } = newMessage
				let currentMessssage = _.isEqual(pushNotificationType, 'appointmentAdd')
					? `New appointment confirmed with ${notificationMessage?.customerName || ''},
					${notificationMessage?.appoinmentNo || ''} on ${notificationMessage?.on || ''}`
					: // Appointment reschedule
					_.isEqual(pushNotificationType, 'appointmentReschedule')
					? `Appointment reschedule for ${notificationMessage?.appoinmentNo || ''}, from
					${notificationMessage?.from || ''} to ${notificationMessage?.to || ''}`
					: //Appointment cancelled
					_.isEqual(pushNotificationType, 'appointmentCancel')
					? `Appointment cancelled with ${notificationMessage?.customerName || ''},
					${notificationMessage?.appoinmentNo || ''} on ${notificationMessage?.on || ''}`
					: //Bill payment
					_.isEqual(pushNotificationType, 'Quick sale') ||
					  _.isEqual(pushNotificationType, 'Membership') ||
					  _.isEqual(pushNotificationType, 'Customer info appointment') ||
					  _.isEqual(pushNotificationType, 'Pharma') ||
					  _.isEqual(pushNotificationType, 'EMR Bill')
					? `${notificationMessage?.customerName || ''} has successfully paid bill amount of ${notificationMessage?.billAmount || ''} to ${
							notificationMessage?.practiceName || ''
					  } through online mode`
					: // Consult Message
					_.isEqual(pushNotificationType, 'consult')
					? `${notificationMessage?.customerName || 'Client'} is trying to reach you, please do reply`
					: // Presciption
					_.isEqual(pushNotificationType, 'presciption')
					? `${'specialist name'}, prescribed for ${'patient name'}${'appointment number'}.`
					: // Distributor
					_.isEqual(pushNotificationType, 'Distributor')
					? `${notificationMessage?.distributorName || ''} has added products in draft for ${notificationMessage?.customerName || ''}`
					: //Pharmacist
					_.isEqual(pushNotificationType, 'Pharmacist')
					? `${notificationMessage?.specialistName || ''}, prescribed for ${notificationMessage?.patientName || ''}, ${
							notificationMessage?.appointmentNumber || ''
					  }`
					: `${pushNotificationType} notification received`

				// Playing Sound
				playAudio()
				// pushing pop-up
				showBottomNotification(currentMessssage, newMessage)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BearerToken])

	return (
		<>
			<Tooltip title='Notification' followCursor>
				<IconButton onClick={userMenuClick} className={classes.iconBtn} size='large' id='notification_icon'>
					<Badge badgeContent={notificationsList?.length} color='secondary' className={classes.badge}>
						<NotificationsNoneIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				open={Boolean(userMenu)}
				className={classes.rootPopper}
				anchorEl={userMenu}
				onClose={userMenuClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
					},
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-6',
				}}
			>
				<Box className={classes.boxContainer}>
					{!_.isEmpty(notificationsList) && (
						<div className={classes.clrAllBox}>
							<Typography className={classes.clrAll} onClick={clearAllNotification}>
								Clear all
							</Typography>
						</div>
					)}

					{!_.isEmpty(notificationsList) &&
						notificationsList?.map((item) => {
							let { pushNotificationType, notificationMessage, isSeen } = item
							return _.isEqual(pushNotificationType, 'appointmentAdd') ? (
								<>
									{/*New Appointment */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Appointment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													New appointment confirmed with <span>{notificationMessage?.customerName || ''}</span>,{' '}
													<span>{notificationMessage?.appoinmentNo || ''}</span> on <span>{notificationMessage?.on || ''}</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'appointmentReschedule') ? (
								<>
									{/*Appointment Reschedule */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Appointment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													Appointment reschedule for <span>{notificationMessage?.appoinmentNo || ''}</span>, from{' '}
													<span>{notificationMessage?.from || ''}</span> to <span>{notificationMessage?.to || ''}</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'appointmentCancel') ? (
								<>
									{/*Appointment Cancelled */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Appointment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													Appointment cancelled with <span>{notificationMessage?.customerName || ''}</span>,{' '}
													<span>{notificationMessage?.appoinmentNo || ''}</span> on <span>{notificationMessage?.on || ''}</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'Quick sale') ||
							  _.isEqual(pushNotificationType, 'Membership') ||
							  _.isEqual(pushNotificationType, 'Customer info appointment') ||
							  _.isEqual(pushNotificationType, 'Pharma') ||
							  _.isEqual(pushNotificationType, 'EMR Bill') ? (
								<>
									{/* Bill Payment */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Payment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													<span>{notificationMessage?.customerName || ''}</span> has successfully paid bill amount of
													<span> {notificationMessage?.billAmount || ''} </span>
													to <span>{notificationMessage?.practiceName || ''} </span>
													through online mode
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'consult') ? (
								<>
									{/* Consult Msg */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/WhatsApp.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													<span>{notificationMessage?.customerName || 'Client'}</span> is trying to reach you, please do reply
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification()}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>{' '}
								</>
							) : _.isEqual(pushNotificationType, 'presciption') ? (
								<>
									{/* Add Prescription */}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Prescription.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													<span>{'specialist name'}</span>, prescribed for <span>{'patient name'}</span> <span>{'appointment number'}</span>.
													<br />
													click to{' '}
													<span className={classes.addBill} onClick={() => alert('Add bill clicked')}>
														Add bill
													</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'Distributor') ? (
								<>
									{/* Distributor*/}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Payment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													{notificationMessage?.distributorName || ''} has added products in draft for {notificationMessage?.customerName || ''}
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'Pharmacist') ? (
								<>
									{/* Pharmacist*/}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Prescription.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													<span>{notificationMessage?.specialistName || ''}</span>, prescribed for{' '}
													<span>{notificationMessage?.patientName || ''}</span>, <span>{notificationMessage?.appointmentNumber || ''}</span>
												</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : _.isEqual(pushNotificationType, 'healthFileExtraction') ? (
								<>
									{/* Pharmacist*/}
									<Grid container xs={12} gap={1.4} className={clsx(classes.gridContainer, !isSeen && classes.unSeenBgColor)}>
										<Grid container key='notification_container' xs={9.5} onClick={() => handleMessageClick(item)}>
											<Grid item xs={2.2}>
												<Avatar alt='notification icon' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Notification_Icons/Appointment.svg`} />
											</Grid>
											<Grid item xs={9.7}>
												<Typography className={classes.notiMsg}>
													<span>{notificationMessage?.customerName}</span> has shared a report.
												</Typography>
												<Typography className={classes.notiMsg}>Please click to view the extracted report</Typography>
											</Grid>
										</Grid>

										<Grid item xs={2} className={classes.notiClear}>
											<IconButton onClick={() => clearSingleNotification(item)}>
												<HighlightOffIcon />
											</IconButton>
											<Typography>{moment(item?.date).format('h:mm A')}</Typography>
										</Grid>
									</Grid>
								</>
							) : (
								<></>
							)
						})}

					{_.isEmpty(notificationsList) && (
						<Grid container xs={12}>
							<Grid item xs={12}>
								<Typography className={classes.noNoti}>
									<b>No notifications yet</b>
									<br />
									When you get notifications, they'll show up here
								</Typography>
							</Grid>
						</Grid>
					)}
				</Box>
			</Popover>
		</>
	)
}
export default HeaderNotification
