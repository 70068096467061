import React, { useCallback, useEffect, useRef, useState } from 'react'
import MicrositeTrackApi from '../../../../services/ProfileSetup/MicrositeTracking/api'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import Chart from 'react-apexcharts'
import { Container, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import DateRangePick from './dateRangePick'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'

const MicrositeTrack = () => {
	const [trackingData, setTrackingData] = useState(null)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
	const { mastuuid } = useSelector((state) => state.Authentication)
	const [date, setDate] = useState([`${moment().subtract(1, 'M').format('YYYY-MM-DD')}`, `${moment().format('YYYY-MM-DD')}`])

	const fetchTrackData = useCallback(() => {
		setLoading(true)
		dispatch(showLoader('Data fetching please wait...'))
		const onSuccess = (res) => {
			setLoading(false)
			dispatch(hideLoader())
			setTrackingData(res?.data)
		}
		const onFailure = (err) => {
			setLoading(false)
			dispatch(hideLoader())
			setTrackingData({})
		}
		MicrositeTrackApi.trackingDataApi({ tentUuid: mastuuid, fromDate: `${date?.at(0)} 00:00:00`, toDate: `${date?.at(1)} 23:59:59` }).then(
			onSuccess,
			onFailure
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, mastuuid])

	useEffect(() => {
		fetchTrackData()
	}, [fetchTrackData])

	const mapContainerStyle = {
		width: '100%',
		height: '600px',
	}

	// Center the map on a global view
	const center = {
		lat: 20.0,
		lng: 0.0,
	}
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Add your Google Maps API key here
	})

	const [selectedMarker, setSelectedMarker] = useState(null)
	const mapRef = useRef(null)

	if (loadError) return <div>Error loading map</div>
	if (!isLoaded) return <div>Loading map...</div>

	// Page Visits Chart
	const pageVisitsChartOptions = {
		chart: {
			type: 'bar',
			fontFamily: 'poppins',
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 4,
				barHeight: '50%',
				colors: {
					ranges: [
						{
							from: 0,
							to: 100,
							color: '#0062DD',
						},
					],
				},
			},
		},
		series: {
			name: 'Visit',
		},

		xaxis: {
			categories: trackingData?.pageVisits?.map((visit) => (visit?.page).replace(/^https?:\/\//, '')),
			labels: {
				style: {
					colors: '#333',
					fontSize: '14px',
					fontFamily: 'poppins',
					fontWeight: 500,
				},
			},
			title: {
				text: 'Page Visits Count',
				style: {
					fontSize: '16px',
					fontWeight: 500,
					fontFamily: 'poppins',
					color: '#333',
				},
			},
		},
		yaxis: {
			title: {
				text: 'Page Url',
				style: {
					fontSize: '16px',
					fontWeight: 500,
					fontFamily: 'poppins',
					color: '#333',
				},
			},
			labels: {
				style: {
					colors: '#333',
					fontSize: '12px',
					fontFamily: 'poppins',
					fontWeight: 500,
				},
			},
		},
		tooltip: {
			shared: false,
			intersect: true,
			x: {
				formatter: (value) => `Page: ${value}`,
			},
			y: {
				formatter: (value) => `Visits: ${value}`,
			},
		},
		colors: ['#0062DD'],
		title: {
			text: 'Page Visits',
			style: {
				fontSize: '16px',
				fontWeight: 500,
			},
		},
	}
	const pageVisitsSeries = [{ data: trackingData?.pageVisits?.map((visit) => visit?.visit_count) }]

	// Browser Visits Chart
	const browserVisitsChartOptions = {
		chart: {
			type: 'pie',
			fontFamily: 'poppins',
			width: 400,
			height: 400,
		},
		labels: trackingData?.browserVisits?.map((visit) => visit?.browser),
		colors: ['#0062DD', '#FF83C6', '#68C8FF', '#75E252'],
		title: {
			text: 'Browser Visits',
			style: {
				fontSize: '16px',
				fontWeight: 500,
			},
		},
		plotOptions: {
			pie: {
				donut: {
					size: '54%',
					background: '#fff',
					labels: {
						show: true,
						total: {
							show: true,
							showAlways: true,
							label: 'Overall',
							fontSize: '1.5rem',
							fontFamily: 'sans-serif',
							fontWeight: 600,
							color: '#000',
							formatter: function (w) {
								return w.globals.seriesTotals.reduce((a, b) => {
									return a + b
								})
							},
						},
					},
				},
			},
		},
	}
	const browserVisitsSeries = trackingData?.browserVisits?.map((visit) => visit?.visit_count)

	// OS Visits Chart
	const osVisitsChartOptions = {
		chart: { type: 'pie', fontFamily: 'poppins' },
		labels: trackingData?.osVisits?.map((visit) => visit?.os),
		colors: ['#0062DD', '#FF83C6', '#75E252', '#68C8FF'],
		title: {
			text: 'OS Visits',
			style: {
				fontSize: '16px',
				fontWeight: 500,
			},
		},
		plotOptions: {
			pie: {
				donut: {
					size: '54%',
					background: '#fff',
					labels: {
						show: true,
						total: {
							show: true,
							showAlways: true,
							label: 'Overall',
							fontSize: '1.5rem',
							fontFamily: 'poppins',
							fontWeight: 600,
							color: '#000',
							formatter: function (w) {
								return w.globals.seriesTotals.reduce((a, b) => {
									return a + b
								})
							},
						},
					},
				},
				dataLabels: {
					style: {
						fontFamily: 'poppins',
						fontSize: '14px',
						color: '#666',
					},
				},
			},
		},
	}
	const osVisitsSeries = trackingData?.osVisits?.map((visit) => visit?.visit_count)
	return (
		<>
			<Container maxWidth='lg'>
				<Grid container spacing={3} style={{ marginBottom: 12, alignItems: 'center' }}>
					<Grid item xs={6}>
						<Typography variant='h6' gutterBottom>
							Analytics Overview
						</Typography>
					</Grid>
					<Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<DateRangePick
							getDateRange={(dateFromPicker) => {
								setDate(dateFromPicker)
							}}
						/>
					</Grid>
				</Grid>
			</Container>
			{loading ? (
				<></>
			) : !_.isEqual(trackingData, null) &&
			  !_.isEqual(trackingData?.browserVisits, []) &&
			  !_.isEqual(trackingData?.countryVisits, []) &&
			  !_.isEqual(trackingData?.osVisits, []) &&
			  !_.isEqual(trackingData?.pageVisits, []) &&
			  !_.isEqual(trackingData?.totalVisits, 0) ? (
				<Container maxWidth='lg'>
					<Grid container spacing={3}>
						{/* Total Visits */}
						<Grid item xs={12}>
							<Paper elevation={3} style={{ padding: '16px', background: '#0062DD' }}>
								<Typography variant='h6' style={{ fontFamily: 'poppins', color: '#FFF' }}>
									Total Visits: {trackingData?.totalVisits}
								</Typography>
							</Paper>
						</Grid>

						{/* Page Visits Chart */}
						<Grid item xs={12} md={12}>
							<Paper elevation={3} style={{ padding: '16px' }}>
								<Chart options={pageVisitsChartOptions} series={pageVisitsSeries} type='bar' minHeight={'500'} />
							</Paper>
						</Grid>

						{/* Browser Visits Chart */}
						<Grid item xs={12} md={6}>
							<Paper elevation={3} style={{ padding: '16px' }}>
								<Chart options={browserVisitsChartOptions} series={browserVisitsSeries?.map(Number)} type='donut' height={350} />
							</Paper>
						</Grid>

						{/* OS Visits Chart */}
						<Grid item xs={12} md={6}>
							<Paper elevation={3} style={{ padding: '16px' }}>
								<Chart options={osVisitsChartOptions} series={osVisitsSeries?.map(Number)} type='donut' height={350} />
							</Paper>
						</Grid>

						{/* Country Visits Map */}
						<Grid item xs={12}>
							<GoogleMap
								mapContainerStyle={mapContainerStyle}
								zoom={2}
								center={center}
								onLoad={(map) => {
									mapRef.current = map
								}}
							>
								{trackingData?.countryVisits?.map((visit, index) => (
									<Marker key={index} position={{ lat: visit.latitude, lng: visit.longitude }} onClick={() => setSelectedMarker(visit)} />
								))}

								{selectedMarker && (
									<InfoWindow position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }} onCloseClick={() => setSelectedMarker(null)}>
										<Paper elevation={3} style={{ padding: '16px', background: '#0062DD' }}>
											<h4 style={{ fontFamily: 'poppins', color: '#FFF', fontSize: 14, fontWeight: 400 }}>
												{selectedMarker?.city}, {selectedMarker?.region}, {selectedMarker?.country}
											</h4>
											<p style={{ fontFamily: 'poppins', color: '#FFF', fontSize: 12, fontWeight: 400 }}>Visits: {selectedMarker?.visit_count}</p>
										</Paper>
									</InfoWindow>
								)}
							</GoogleMap>
						</Grid>
					</Grid>
				</Container>
			) : (
				<Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
					<img src={`${process?.env?.REACT_APP_IMAGEKIT_URL}/No_dataimages/No_analytics_data.svg`} alt='no_analytics' height={400} width={400} />
				</Container>
			)}
		</>
	)
}

export default MicrositeTrack
