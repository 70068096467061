import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	trackingDataApi: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_MICROSITE_TRACK_DATA}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
