import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAISitemapStatus: (params) => {
		return axios.get(`${API_ENDPOINTS.AI_SITEMAP_STATUS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	postSiteMapUrl: (body) => {
		return axios.post(`${API_ENDPOINTS.POST_SITE_MAP_URL}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	postSiteMapFiles: (body) => {
		return axios.post(`${API_ENDPOINTS.POST_SITE_MAP_FILES}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	postAiBotDetails: (body) => {
		return axios.post(`${API_ENDPOINTS.POST_AI_BOT_DETAILS}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	getAibotDetails: (mastUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_AI_BOT_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid } },
		})
	},
	rollBack: (mastUuid, params) => {
		return axios.get(`${API_ENDPOINTS.AI_SITEMAP_ROLLBACK}/${mastUuid}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
