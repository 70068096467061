import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, TextField, Typography, InputAdornment, FormGroup, FormControlLabel, Checkbox, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomBtn from 'app/sharedComponents/Button'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'
import customLabelsApi from '../../../../services/ProfileSetup/Customlabels/api'
import { getResponseMessage } from 'utils'

const useStyles = makeStyles((theme) => ({
	domainField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-error': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused.Mui-error': {
			border: 'none',
			borderRadius: 8,
		},
	},
	domainLabel: {
		fontWeight: 600,
		fontSize: 13,
		marginBottom: 6,
	},
	labelContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	seoDescriptionLength: {
		position: 'absolute',
		bottom: '8px',
		right: '8px',
		fontWeight: 500,
		color: '#000',
	},
	buttonMain: {
		display: 'flex',
		gap: 16,
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		padding: '30px',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	errorText: {
		'& .MuiTypography-root': {
			color: 'red',
		},
	},
}))

const CustomLabels = ({ moveBackTab, moveNextTab }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const auth = useSelector((state) => state?.Authentication)
	const { mastuuid } = auth
	const groupUserName = useSelector((state) => state?.GroupUser?.groupUserName)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const isCare = _.isEqual(groupName, 'health care')
	const [loading, setLoading] = useState(true)
	const [domainName, setDomainName] = useState('')

	const initialValues = {
		aboutPractise: {},
		specialist: {},
		events: {},
		membership: {},
		facility: {},
		specialization: {},
	}

	const validationSchema = Yup.object({
		// aboutPractise: Yup.string().max(60, 'About Practice must be 60 characters or less').required('Meta title is required'),
		// specialist: Yup.string().max(60, 'Specialist must be 60 characters or less').required('Meta title is required'),
		// events: Yup.string().max(60, 'Events must be 60 characters or less').required('Meta title is required'),
		// membership: Yup.string().max(60, 'Membership must be 60 characters or less').required('Meta title is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			postCustomLabels(values)
		},
	})

	const postCustomLabels = (values) => {
		const body = {
			aboutPractise: values.aboutPractise,
			specialist: values.specialist,
			events: values.events,
			membership: values?.membership,
			facility: values?.facility,
			specialization: values?.specialization,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		customLabelsApi.postCustomLabels({ customDetails: { ...body } }, mastuuid, domainName).then(onSuccess, onFailure)
	}

	const getSeoDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		setLoading(true)
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setDomainName(res?.data?.data?.domainName)
			formik.setValues({
				aboutPractise: {
					fieldName: res?.data?.data?.customLabels?.aboutPractise?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.aboutPractise?.isDisplay || false,
				},
				specialist: {
					fieldName: res?.data?.data?.customLabels?.specialist?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.specialist?.isDisplay || false,
				},
				events: {
					fieldName: res?.data?.data?.customLabels?.events?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.events?.isDisplay || false,
				},
				membership: {
					fieldName: res?.data?.data?.customLabels?.membership?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.membership?.isDisplay || false,
				},
				facility: {
					fieldName: res?.data?.data?.customLabels?.facility?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.facility?.isDisplay || false,
				},
				specialization: {
					fieldName: res?.data?.data?.customLabels?.specialization?.fieldName || '',
					isDisplay: res?.data?.data?.customLabels?.specialization?.isDisplay || false,
				},
			})
			setLoading(false)
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			setLoading(false)
		}

		mastuuid && customLabelsApi.getCustomLabels(mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, mastuuid])

	useEffect(() => {
		getSeoDetails()
	}, [getSeoDetails])

	return (
		<Box minHeight={'55vh'}>
			{loading ? (
				<Grid container spacing={2}>
					{Array.from({ length: 6 }).map((_, index) => (
						<Grid key={index} item xs={12} sm={6} className={classes.domainField}>
							<div>
								<Skeleton variant='rounded' height={20} width={'100%'} sx={{ mb: '12px' }} />
								<Skeleton variant='rounded' height={50} width={'100%'} />
							</div>
						</Grid>
					))}
				</Grid>
			) : (
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<div className={classes?.labelContainer}>
								<Typography className={classes.domainLabel}>About Practice</Typography>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik?.values?.aboutPractise?.isDisplay}
											onChange={(event) => formik?.setFieldValue('aboutPractise.isDisplay', event.target.checked)}
											sx={{
												'&.Mui-checked': {
													color: '#0062DD',
												},
											}}
										/>
									}
									label='Is Display'
								/>
							</div>
							<TextField
								size='small'
								fullWidth
								id='aboutPractise'
								placeholder='About Practice'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.aboutPractise?.fieldName?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.aboutPractise?.fieldName}
								onChange={(e) => formik.setFieldValue('aboutPractise.fieldName', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.aboutPractise && Boolean(formik.errors.aboutPractise)}
								// helperText={formik.touched.aboutPractise && formik.errors.aboutPractise}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<div className={classes?.labelContainer}>
								<Typography className={classes.domainLabel}>Specialists</Typography>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik?.values?.specialist?.isDisplay}
											onChange={(event) => formik?.setFieldValue('specialist.isDisplay', event.target.checked)}
											sx={{
												'&.Mui-checked': {
													color: '#0062DD',
												},
											}}
										/>
									}
									label='Is Display'
								/>
							</div>
							<TextField
								size='small'
								fullWidth
								id='specialist'
								placeholder='Specialists'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.specialist?.fieldName?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.specialist?.fieldName}
								onChange={(e) => formik.setFieldValue('specialist.fieldName', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.specialist && Boolean(formik.errors.specialist)}
								// helperText={formik.touched.specialist && formik.errors.specialist}
							/>
						</Grid>
						{!isCare && (
							<Grid item xs={12} sm={6} className={classes.domainField}>
								<div className={classes?.labelContainer}>
									<Typography className={classes.domainLabel}>Facility</Typography>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik?.values?.facility?.isDisplay}
												onChange={(event) => formik?.setFieldValue('facility.isDisplay', event.target.checked)}
												sx={{
													'&.Mui-checked': {
														color: '#0062DD',
													},
												}}
											/>
										}
										label='Is Display'
									/>
								</div>
								<TextField
									size='small'
									fullWidth
									id='facility'
									placeholder='Facility'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<Typography fontWeight={500} color={'#000'}>
													{formik?.values?.facility?.fieldName?.length || 0}/40
												</Typography>
											</InputAdornment>
										),
									}}
									inputProps={{ maxLength: 40 }}
									value={formik?.values?.facility?.fieldName}
									onChange={(e) => formik.setFieldValue('facility.fieldName', e.target.value)}
									// onBlur={formik.handleBlur}
									// error={formik.touched.facility && Boolean(formik.errors.facility)}
									// helperText={formik.touched.facility && formik.errors.facility}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<div className={classes?.labelContainer}>
								<Typography className={classes.domainLabel}>Specialization</Typography>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik?.values?.specialization?.isDisplay}
											onChange={(event) => formik?.setFieldValue('specialization.isDisplay', event.target.checked)}
											sx={{
												'&.Mui-checked': {
													color: '#0062DD',
												},
											}}
										/>
									}
									label='Is Display'
								/>
							</div>
							<TextField
								size='small'
								fullWidth
								id='specialization'
								placeholder='Specialization'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.specialization?.fieldName?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.specialization?.fieldName}
								onChange={(e) => formik.setFieldValue('specialization.fieldName', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.specialization && Boolean(formik.errors.specialization)}
								// helperText={formik.touched.specialization && formik.errors.specialization}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<div className={classes?.labelContainer}>
								<Typography className={classes.domainLabel}>Events</Typography>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik?.values?.events?.isDisplay}
											onChange={(event) => formik?.setFieldValue('events.isDisplay', event.target.checked)}
											sx={{
												'&.Mui-checked': {
													color: '#0062DD',
												},
											}}
										/>
									}
									label='Is Display'
								/>
							</div>
							<TextField
								size='small'
								fullWidth
								id='events'
								placeholder='Events'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.events?.fieldName?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.events?.fieldName}
								onChange={(e) => formik.setFieldValue('events.fieldName', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.events && Boolean(formik.errors.events)}
								// helperText={formik.touched.events && formik.errors.events}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<div className={classes?.labelContainer}>
								<Typography className={classes.domainLabel}>Membership</Typography>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik?.values?.membership?.isDisplay}
											onChange={(event) => formik?.setFieldValue('membership.isDisplay', event.target.checked)}
											sx={{
												'&.Mui-checked': {
													color: '#0062DD',
												},
											}}
										/>
									}
									label='Is Display'
								/>
							</div>
							<TextField
								size='small'
								fullWidth
								id='membership'
								placeholder='Membership'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.membership?.fieldName?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.membership?.fieldName}
								onChange={(e) => formik.setFieldValue('membership.fieldName', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.membership && Boolean(formik.errors.membership)}
								// helperText={formik.touched.membership && formik.errors.membership}
							/>
						</Grid>
					</Grid>
					<div className={classes.buttonMain}>
						<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
						<CustomBtn type='submit'>{_.isEqual(groupUserName, 'owner') ? 'Save' : 'Save & Next'}</CustomBtn>
					</div>
				</form>
			)}
		</Box>
	)
}

export default CustomLabels
