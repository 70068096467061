import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { currencyCode, numberToIndianvalue } from 'utils'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up('xs')]: {
			padding: 14,
		},
		[theme.breakpoints.up('md')]: {
			padding: 20,
		},
		'& .MuiTypography-subtitle1': {
			color: 'rgba(99, 99, 99, 0.9)',
		},
	},
	container: {
		paddingBlockStart: 14,
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'space-around',
			gap: 14,
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
			gap: 18,
		},
		'@global': {
			' div:nth-of-type(1)': {
				backgroundColor: '#E4F9FC',
			},
			' div:nth-of-type(2)': {
				backgroundColor: '#E8FEED',
			},
			' div:nth-of-type(3)': {
				backgroundColor: '#FFF9EB',
			},
			' div:nth-of-type(4)': {
				backgroundColor: '#F0EEFF',
			},
			' div:nth-of-type(5)': {
				backgroundColor: '#EEF8FF',
			},
			' div:nth-of-type(6)': {
				backgroundColor: '#F5EEF8',
			},
		},
	},
	card: {
		paddingInline: 10,
		borderRadius: 16,
		width: '100%',
		textAlign: 'center',
		boxShadow: 'rgba(99, 99, 99, 0.3) 0px 1px 4px 0px',
		[theme.breakpoints.up('xs')]: {
			maxWidth: 175,
			paddingBlock: 14,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 258,
			paddingBlock: 20,
		},
		'& .MuiTypography-subtitle1': {
			color: 'rgba(99, 99, 99, 0.9)',
			[theme.breakpoints.up('md')]: {
				paddingBlockEnd: 8,
				whiteSpace: 'pre',
			},
		},
		'& .MuiTypography-h6': {
			color: '#2A2A2A',
		},
	},
}))

function Statistics(props) {
	const classes = useStyles()
	const { report, pageData, isCare, pageQuery } = props
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const currency = useSelector((state) => state?.dynamicMenu?.userData?.currencyCode)

	const isCancelled = pageQuery?.cancelled

	return (
		<div className={classes.root}>
			<Typography variant='subtitle1'>Statistics</Typography>
			<div className={classes.container}>
				{report === 'NewPatients' && (
					<>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Total {isCare ? 'patients' : 'clients'}</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue ? (typeof pageData?.totalValue === 'number' ? numberToIndianvalue(pageData?.totalValue) : 0) : 0}
							</Typography>
						</div>
					</>
				)}

				{report === 'Appointments' && (
					<>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Total appointments</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue ? (typeof pageData?.totalValue === 'number' ? numberToIndianvalue(pageData?.totalValue) : 0) : 0}
							</Typography>
						</div>
					</>
				)}

				{report === 'Distributor' && (
					<>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Total no. sales</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue?.TotalSales ? numberToIndianvalue(pageData?.totalValue?.TotalSales || 0, currencyType) : 0}
							</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Total price ({currency})</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue?.TotalAmount
									? typeof pageData?.totalValue?.TotalAmount === 'number'
										? currencyCode(pageData?.totalValue?.TotalAmount || 0, currencyType)
										: 0
									: 0}
							</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Commission value ({currency})</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue?.CommissionAmount
									? typeof pageData?.totalValue?.CommissionAmount === 'number'
										? currencyCode(pageData?.totalValue?.CommissionAmount || 0, currencyType)
										: 0
									: 0}
							</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>RO commission value ({currency})</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue?.RoCommissionValue
									? typeof pageData?.totalValue?.RoCommissionValue === 'number'
										? currencyCode(pageData?.totalValue?.RoCommissionValue || 0, currencyType)
										: 0
									: 0}
							</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Final price ({currency})</Typography>
							<Typography variant='h6'>
								{pageData?.totalValue?.FinalPrice
									? typeof pageData?.totalValue?.FinalPrice === 'number'
										? currencyCode(pageData?.totalValue?.FinalPrice || 0, currencyType)
										: 0
									: 0}
							</Typography>
						</div>
					</>
				)}

				{(report === 'InvoicedDetail' || report === 'Membership') && (
					<>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Cost ({currency})</Typography>
							<Typography variant='h6'>{currencyCode(pageData?.totalValue?.totalCost || 0, currencyType)}</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Discount ({currency})</Typography>
							<Typography variant='h6'>{currencyCode(pageData?.totalValue?.totalDiscount || 0, currencyType)}</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Tax ({currency})</Typography>
							<Typography variant='h6'>{currencyCode(pageData?.totalValue?.totalTax || 0, currencyType)}</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Amount invoiced ({currency})</Typography>
							<Typography variant='h6'>{currencyCode(pageData?.totalValue?.totalInvoiceAmount || 0, currencyType)}</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Amount received ({currency})</Typography>
							<Typography variant='h6'>
								{/* This isCancelled is hardcodded as 0, Due to an request from Backend.. For any clarification in future, contact Pavithra from java */}
								{isCancelled ? currencyCode(0, currencyType) : currencyCode(pageData?.totalValue?.totalAmountPaid || 0, currencyType)}
							</Typography>
						</div>
						<div className={classes.card}>
							<Typography variant='subtitle1'>Amount Due ({currency})</Typography>
							<Typography variant='h6'>{currencyCode(pageData?.totalValue?.amtDue || 0, currencyType)}</Typography>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default Statistics
