import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, Avatar } from '@mui/material'
import { useDispatch } from 'react-redux'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Button from 'app/sharedComponents/Button'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 5,
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: 5,
		marginRight: 8,
		width: 50,
		height: 50,
		padding: 4,
		boxSizing: 'border-box',
	},
	uploadIcon: {
		color: '#414A58',
		opacity: 0.7,
	},
	thumbInne: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
	},
	container: {
		// padding: 14,
		borderRadius: 14,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		// gap: 16,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			gap: 0,
		},
	},
	uploadContainer: {
		background: '#ffffff',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: 100,
		alignItems: 'center',
		borderRadius: 14,
		height: 92,
		boxShadow: '-5px 5px 17px #e3e3e3, -5px 17px #ffffff',
	},
	dropzone: {
		display: 'flex',
		cursor: 'pointer',
		gap: 8,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: 100,
		height: 100,
		boxShadow: '0 4px 8px 0 rgb(0 0 0 / 6%), 0px 1px 20px 0 rgb(255 255 255 /12%)',
		opacity: 1,
		marginBlock: 12,
		borderRadius: 18,
		'& .MuiButton-root': {
			color: '#636669',
			border: '1px solid #636669',
		},
	},
	icon: {
		color: '#767676',
		marginTop: theme.spacing(1),
		fontSize: 38,
	},
	dragText: {
		color: '#767676',
		marginTop: theme.spacing(1),
	},
	mainImg: {
		width: 100,
		height: 100,
		overflow: 'hidden',
		borderRadius: 12,
		marginBlock: 12,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#eee',
	},
	deletIcon: {
		cursor: 'pointer',
	},
	mainImgUpload: {
		display: 'flex',
		gap: 6,
	},
	mainContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	profileData: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
		},
	},
	userLogo: {
		width: 100,
		height: 100,
		borderRadius: 12,
	},
	tooltip: {
		background: '#fff',
		color: '000',
		'& .MuiTooltip-tooltip': {
			color: '#000',
			background: '#fff',
		},
	},
	profilephoto: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
			fontWeight: 600,
		},
		[theme.breakpoints.up('sm')]: {
			fontWeight: 600,
			fontSize: 16,
		},
	},
	removeBtn: {
		color: '#2A2A2A',
		backgroundColor: '#F5F4F6',
		marginTop: '16px',
		'&:hover': {
			backgroundColor: '#f5f4f6',
		},
	},
	helperTextContainer: {
		marginTop: 8,
		textAlign: 'center',
		'& .MuiTypography-body1 ': {
			fontSize: 12,
			color: '#808080',
		},
	},
}))

const DropZone = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { displayUploadImage, imgResponse, dropzonestyle, setEstablishmentFileResponse, fileType } = props

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 5242880
	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxSize: formatBytes(maxFileSize),
		maxFiles: 1,
		accept: 'image/jpge,image/jpeg,image/png,image/svg',

		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				displayUploadImage(acceptedFiles)
			}
		},
	})

	const removeTableData = () => {
		setEstablishmentFileResponse('')
	}

	return (
		<div className={`${classes.container} ${dropzonestyle}`}>
			{!_.isEmpty(imgResponse) ? (
				<>
					<Avatar
						src={imgResponse}
						className={classes.avatar}
						sx={{ width: 100, height: 100, borderRadius: 1.5, bgcolor: '#212F3C' }}
						variant='square'
					/>
				</>
			) : (
				<div
					className={classes.mainImg}
					{...getRootProps()}
					onClick={open}
					// style={{ backgroundImage: "url('${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/UploadFiles.svg')" }}
				>
					<input {...getInputProps()} />
					<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Vector.svg`} alt='UploadFiles' style={{ width: '50%', height: '50%' }} />
				</div>
			)}

			<div className={classes.profileData}>
				<div className={classes.mainContainer}>
					{imgResponse ? (
						<Button onClick={() => removeTableData()} customStyle={classes.removeBtn}>
							Remove
						</Button>
					) : (
						<Button onClick={open} disabled={imgResponse ? true : false}>
							Upload
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

export default DropZone
