import React from 'react'
import { Grid, Chip, Autocomplete, TextField, Icon, IconButton } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import CancelIcon from '@mui/icons-material/Cancel'

const TagComponent = ({ tagOptions, tags, setTags, isEditMode, formik }) => {
	const filter = createFilterOptions()
	return (
		<Grid container>
			<Grid container>
				<Autocomplete
					id='patienttags'
					name='patienttags'
					value={tags}
					color='secondary'
					fullWidth
					multiple={true}
					size='small'
					options={tagOptions}
					disableCloseOnSelect={true}
					filterSelectedOptions={true}
					// noOptionsText={'No options'}
					limitTags={2}
					// onChange={(event, newValue) => {
					// 	if (typeof newValue === 'string') {
					// 		setTags((prevState) => [
					// 			...prevState,
					// 			{
					// 				new: true,
					// 				uuidOrName: newValue,
					// 			},
					// 		])
					// 	} else if (newValue && newValue.inputValue) {
					// 		setTags((prevState) => [
					// 			...prevState,
					// 			{
					// 				new: true,
					// 				mastTagName: newValue,
					// 				uuidOrName: newValue,
					// 			},
					// 		])
					// 	} else {
					// 		setTags(newValue)
					// 	}
					// }}
					onChange={(event, newValue) => {
						let updatedTags

						if (typeof newValue === 'string') {
							updatedTags = [
								...tags,
								{
									new: true,
									uuidOrName: newValue,
								},
							]
						} else if (newValue && newValue.inputValue) {
							updatedTags = [
								...tags,
								{
									new: true,
									tentGroupName: newValue,
									uuidOrName: newValue.inputValue,
								},
							]
						} else {
							updatedTags = newValue
						}
						setTags(updatedTags)
						formik.setFieldValue('tags', updatedTags)
					}}
					filterOptions={(options, params) => {
						const filtered = filter(options, params)

						const { inputValue } = params
						const isExisting = options.some((option) => inputValue === option.mastTagName)
						if (inputValue !== '' && !isExisting) {
							filtered.push({
								inputValue: inputValue,
								mastTagName: `Add "${inputValue}"`,
								uuidOrName: inputValue,
								new: true,
							})
						}
						return filtered
					}}
					selectOnFocus
					clearOnBlur
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option
						}
						if (option.inputValue) {
							return option.inputValue
						}
						return option.mastTagName
					}}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								variant='outlined'
								label={option?.inputValue ? option?.inputValue : option?.mastTagName}
								size='small'
								deleteIcon={
									isEditMode &&
									option?.type === 'Smart' && (
										<IconButton disabled>
											<Icon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<CancelIcon style={{ fontSize: 16 }} />
											</Icon>
										</IconButton>
									)
								}
								{...getTagProps({ index })}
							/>
						))
					}
					renderOption={(props, option) => <li {...props}>{option.mastTagName}</li>}
					renderInput={(params) => (
						<TextField
							{...params}
							size='small'
							color='secondary'
							className='mt-1 mb-0'
							label='Tag name'
							error={formik.touched.tags && formik.errors.tags}
							helperText={formik.touched.tags && formik.errors.tags && formik.errors.tags}
						/>
					)}
				/>
			</Grid>
		</Grid>
	)
}

export default TagComponent
