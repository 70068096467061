import { React } from 'react'
import { Grid, Chip, Autocomplete, TextField, Icon, IconButton } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import CancelIcon from '@mui/icons-material/Cancel'

const GroupName = ({ groupOptions, groups, isEditMode, setGroups, formik }) => {
	const filter = createFilterOptions()
	return (
		<Grid container>
			<Grid container>
				<Autocomplete
					id='patientgroups'
					name='patientgroups'
					value={groups}
					color='secondary'
					fullWidth
					multiple={true}
					size='small'
					options={groupOptions}
					disableCloseOnSelect={true}
					filterSelectedOptions={true}
					// noOptionsText={'No options'}
					limitTags={2}
					onChange={(event, newValue) => {
						let updatedGroups

						if (typeof newValue === 'string') {
							updatedGroups = [
								...groups,
								{
									new: true,
									uuidOrName: newValue,
								},
							]
						} else if (newValue && newValue.inputValue) {
							updatedGroups = [
								...groups,
								{
									new: true,
									tentGroupName: newValue,
									uuidOrName: newValue.inputValue,
								},
							]
						} else {
							updatedGroups = newValue
						}

						setGroups(updatedGroups)
						formik && formik?.setFieldValue('group', updatedGroups)
					}}
					filterOptions={(options, params) => {
						const filtered = filter(options, params)

						const { inputValue } = params
						const isExisting = options.some((option) => inputValue === option.tentGroupName)
						if (inputValue !== '' && !isExisting) {
							filtered.push({
								inputValue: inputValue,
								tentGroupName: `Add "${inputValue}"`,
								uuidOrName: inputValue,
								new: true,
							})
						}
						return filtered
					}}
					selectOnFocus
					clearOnBlur
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option
						}
						if (option.inputValue) {
							return option.inputValue
						}
						return option.tentGroupName
					}}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								variant='outlined'
								label={option?.inputValue ? option?.inputValue : option?.tentGroupName}
								size='small'
								deleteIcon={
									isEditMode &&
									option?.type === 'Smart' && (
										<IconButton disabled>
											<Icon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<CancelIcon style={{ fontSize: 16 }} />
											</Icon>
										</IconButton>
									)
								}
								{...getTagProps({ index })}
							/>
						))
					}
					renderOption={(props, option) => <li {...props}>{option.tentGroupName}</li>}
					renderInput={(params) => (
						<TextField
							{...params}
							size='small'
							color='secondary'
							className='mt-1 mb-0'
							label='Group name'
							error={formik?.touched?.group && formik?.errors?.group}
							helperText={formik?.touched?.group && formik?.errors?.group && formik?.errors?.group}
						/>
					)}
				/>
			</Grid>
		</Grid>
	)
}

export default GroupName
