import React from 'react'
import { makeStyles } from '@mui/styles'
import { currencyCode, customDateFormat, getImgUrl, numberToIndianvalue } from 'utils'
import _ from 'lodash'
import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'
import DataTable from './DataTable'
import { useEffect } from 'react'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFFFFF',
	},
	reportNameDate: {
		padding: '16px 12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '1px solid #B2BABB70',
	},
	reportNameDateInner: {
		display: 'flex',
		alignItems: 'center',
		gap: 12,

		'@global': {
			' p:nth-of-type(1)': {
				fontSize: 12,
				fontWeight: 400,
			},
			' p:nth-of-type(2)': {
				fontSize: 12,
				fontWeight: 400,
			},
		},
	},
	appliedFilters: {
		padding: '16px 12px',
		borderBottom: '1px solid #B2BABB70',
		background: '#dddddd50',
		'& h4': {
			fontWeight: 500,
			paddingBlockEnd: 10,
		},
	},

	appliedFiltersContainer: {
		background: '#FFFFFF',
	},
	statisticsContainer: {
		paddingBlock: 20,
		paddingInline: 8,
		display: 'flex',
		justifyContent: 'space-evenly',
		gap: 14,
		'@global': {
			' div:nth-of-type(1)': {
				backgroundColor: '#E4F9FC',
			},
			' div:nth-of-type(2)': {
				backgroundColor: '#E8FEED',
			},
			' div:nth-of-type(3)': {
				backgroundColor: '#FFF9EB',
			},
			' div:nth-of-type(4)': {
				backgroundColor: '#F0EEFF',
			},
			' div:nth-of-type(5)': {
				backgroundColor: '#EEF8FF',
			},
		},
	},
	card: {
		paddingInline: 10,
		borderRadius: 16,
		width: '100%',
		textAlign: 'center',
		border: '1px solid #dddddd',
		maxWidth: 246,
		padding: 14,
		'& h5': {
			fontWeight: 400,
			fontSize: 12,
			paddingBlockEnd: 4,
			whiteSpace: 'pre',
		},
		'& p': {
			fontWeight: 500,
			fontSize: 14,
		},
	},

	dataTable: {
		'& table': {
			borderCollapse: 'collapse',
			width: '100%',
			fontSize: 10,
		},
		'& th': {
			textAlign: 'left',
			padding: 8,
			paddingBlock: 12,
			backgroundColor: '#dddddd50',
			borderBottom: '1px solid #dddddd',
			borderTop: '1px solid #dddddd',
			fontWeight: 500,
		},
		'& td': {
			padding: 8,
			paddingBlock: 10,
		},
		'& tr': {
			borderBottom: '1px solid #dddddd',
			textAlign: 'left',
			padding: 8,
		},
	},
	alignRight: {
		textAlign: 'right',
		paddingRight: 8,
	},
	alignCenter: {
		textAlign: 'center',
	},
	alignLeft: {
		textAlign: 'left',
	},
	filterItem: {
		padding: 14,
		display: 'flex',
		gap: 4,
		'& h5': {
			fontWeight: 500,
			maxWidth: '23%',
			width: '100%',
		},
	},
	filterContent: {
		display: 'flex',
		gap: 5,
		flexWrap: 'wrap',
	},

	headerSection: {
		padding: 12,
	},
	nameDetails: {
		paddingBlockStart: 2,
		'& span': {
			display: 'inline-block',
			minWidth: 86,
		},
	},
	headerpatient: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	addressDetails: {
		paddingBlockStart: 2,
		display: 'flex',
		'& span': {
			display: 'block',
			minWidth: 86,
		},
		'& p': {
			marginInlineStart: 4,
		},
	},
	headerpractice: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerpracticeLogoSide: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	headerpracticeAddressSide: {
		// width: '34%',
	},
	headerpatientTwo: {
		// width: '32%',
	},
}))

const FuncComponent = (props) => {
	const classes = useStyles()
	const { report, printRow, pageData, dateForPrint, appliedFiltersForprint, isCare, selectedFilters } = props
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const currency = useSelector((state) => state?.dynamicMenu?.userData?.currencyCode)
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	const [showFilterSection, setShowFilterSection] = useState(true)
	const userData = useSelector((state) => state?.dynamicMenu?.userData)
	const { docDriveUuid, mastAddressVO } = userData
	const token = useSelector((state) => state?.Authentication?.BearerToken)
	const practiceDetails = useSelector((state) => state?.Authentication)

	// REPORT TYPES
	const isInvoicedIncome = _.isEqual(report, 'InvoicedIncome')
	const isPayments = _.isEqual(report, 'Payments')
	const isNewPatients = _.isEqual(report, 'NewPatients')
	const isAppointments = _.isEqual(report, 'Appointments')
	const isBillSummary = _.isEqual(report, 'BillSummary')
	const isInvoicedDetail = _.isEqual(report, 'InvoicedDetail')
	const isDistributor = _.isEqual(report, 'Distributor')
	const isMembership = _.isEqual(report, 'Membership')

	useEffect(() => {
		let filterContainer = document?.getElementById('appliedFiltersGrid')
		if (filterContainer) {
			let hasFilterValues = filterContainer?.hasChildNodes()
			setShowFilterSection(hasFilterValues)
		}
	}, [appliedFiltersForprint])

	return (
		<div className={classes.root}>
			<div className={classes.headerSection}>
				<div className={classes.headerpractice}>
					<div className={classes.headerpracticeLogoSide}>
						{docDriveUuid && (
							<div style={{ marginRight: 12 }}>
								<img src={getImgUrl(docDriveUuid, token)} alt='img' width='104' height='104' />
							</div>
						)}
						<div className={classes.headerpracticeLogoTitle}>
							<h1>{userData?.tentName || ''}</h1>
						</div>
					</div>
					<div className={classes.headerpracticeAddressSide}>
						<p className={classes.nameDetails}>
							<span>Printed on </span>: {customDateFormat(new Date(), dateFormat)}
						</p>
						<p className={classes.nameDetails}>
							<span>Mobile no </span>: {practiceDetails?.countryCode} {practiceDetails?.mobileNo}
						</p>
						<p className={classes.nameDetails}>
							<span>E-mail </span>: {practiceDetails?.userEmail}
						</p>
						{/* {(mastAddressVO?.address1 || mastAddressVO?.address2 || mastAddressVO?.cityCode || mastAddressVO?.stateName) && (
							<div className={classes.addressDetails}>
								<span>Address </span>:
								<p>
									{mastAddressVO?.address1 && (
										<>
											{mastAddressVO?.address1 || ''},<br />
										</>
									)}
									{mastAddressVO?.address2 && (
										<>
											{mastAddressVO?.address2 || ''}
											<br />
										</>
									)}
									{mastAddressVO?.cityCode && (
										<>
											{mastAddressVO?.cityCode || ''}
											<br />
										</>
									)}
									{mastAddressVO?.stateName && <> {mastAddressVO?.stateName || ''}</>}
								</p>
							</div>
						)} */}
					</div>
				</div>
			</div>
			<hr />

			<div className={classes.reportNameDate}>
				<div className={classes.reportNameDateInner}>
					<p>Report name :</p>
					<p>
						{isNewPatients && (isCare ? ' New patients' : ' New clients')}
						{isAppointments && ' Appointments'}
						{isInvoicedDetail && ' Invoiced detail'}
						{isDistributor && ' Distributor'}
						{isMembership && ' Membership'}
					</p>
				</div>
				<div className={classes.reportNameDateInner}>
					<p>Report date :</p>
					<p>{dateForPrint || 'Not Available'}</p>
				</div>
			</div>

			<div className={classes.appliedFilters} style={{ display: showFilterSection ? 'block' : 'none' }}>
				<h4>FILTERS :</h4>
				<div className={classes.appliedFiltersContainer}>
					<Grid container id='appliedFiltersGrid'>
						{/* Referrer */}
						{isNewPatients && !_.isEmpty(appliedFiltersForprint?.referredBy) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Referrer </h5>:
									<div className={classes.filterContent}>
										{appliedFiltersForprint?.referredBy?.map((item, index) => (
											<React.Fragment key={item?.mastLookupValue}>
												{item?.mastLookupValue}
												{_.isEqual(appliedFiltersForprint?.referredBy.length, index + 1) ? '.' : ', '}
											</React.Fragment>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Patient/client Groups */}
						{isNewPatients && !_.isEmpty(appliedFiltersForprint?.patientGroup) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>{isCare ? 'Patient' : 'Client'} Groups </h5>:
									<div className={classes.filterContent}>
										{appliedFiltersForprint?.patientGroup?.map((item, index) => (
											<React.Fragment key={item?.uuid}>
												{item?.tentGroupName}
												{_.isEqual(appliedFiltersForprint?.patientGroup.length, index + 1) ? '.' : ', '}
											</React.Fragment>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Blood Group */}
						{isNewPatients && !_.isEmpty(appliedFiltersForprint?.bloodGroup) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Blood Group </h5>:{' '}
									<div className={classes.filterContent}>
										<React.Fragment class='Fragment'>{appliedFiltersForprint?.bloodGroup?.mastLookupValue}</React.Fragment>
									</div>
								</div>
							</Grid>
						)}

						{/* Show  */}
						{isPayments && !_.isEmpty(appliedFiltersForprint?.show) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Show </h5>
									<div className={classes.filterContent}>: {appliedFiltersForprint?.show}</div>
								</div>
							</Grid>
						)}

						{/* Specialist */}
						{(isInvoicedIncome || isPayments || isAppointments || isBillSummary) && !_.isEmpty(appliedFiltersForprint?.doctor) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Specialist </h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.doctor?.map((item, index) => (
											<span key={item?.tentUserUuid}>
												{`${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || ''}`}
												{_.isEqual(appliedFiltersForprint?.doctor.length, index + 1) ? '.' : ','}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Discount */}
						{(isInvoicedIncome || isPayments) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Discount </h5>
									<div className={classes.filterContent}>
										:
										<span>
											{_.isEqual(appliedFiltersForprint?.discount, 'all')
												? 'All'
												: _.isEqual(appliedFiltersForprint?.discount, 'isZero')
												? 'Is Zero'
												: 'More than Zero'}
										</span>
									</div>
								</div>
							</Grid>
						)}

						{/* Procedure Categories */}
						{isInvoicedIncome && !_.isEmpty(appliedFiltersForprint?.procedureCategories) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Procedure Categories</h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.procedureCategories?.map((item, index) => (
											<span key={item?.uuid}>
												{item?.tentProcedureCatalogName}
												{_.isEqual(appliedFiltersForprint?.procedureCategories?.length, index + 1) ? '.' : ','}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Payment Vendor */}
						{(isInvoicedIncome || isPayments) && !_.isEmpty(appliedFiltersForprint?.paymentVendor) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Payment Vendor </h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.paymentVendor?.map((item, index) => (
											<span key={item?.uuid}>
												{item?.modeName}
												{_.isEqual(appliedFiltersForprint?.paymentVendor?.length, index + 1) ? '.' : ','}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Taxes */}
						{(isInvoicedIncome || isPayments) && !_.isEmpty(appliedFiltersForprint?.taxs) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Taxes </h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.taxs?.map((item, index) => (
											<span key={item?.taxUuid}>
												{item?.taxName}
												{_.isEqual(appliedFiltersForprint?.taxs?.length, index + 1) ? '.' : ','}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Appointments Categories */}
						{isAppointments && !_.isEmpty(appliedFiltersForprint?.appointmentCategories) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Appointment Categories </h5>
									{appliedFiltersForprint?.appointmentCategories?.map((item, index) => (
										<span key={item?.categoryUuid}>
											{item?.categoryName}
											{_.isEqual(appliedFiltersForprint?.appointmentCategories?.length, index + 1) ? '.' : ','}
										</span>
									))}
								</div>
							</Grid>
						)}

						{/* Cancelled Payments */}
						{(isInvoicedIncome || isPayments || isAppointments) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Cancelled Payments </h5>
									<div className={classes.filterContent}>
										: <span>{appliedFiltersForprint?.cancelled ? 'Yes' : 'No'} </span>
									</div>
								</div>
							</Grid>
						)}

						{/* Distributor */}
						{isDistributor && !_.isEmpty(appliedFiltersForprint?.distributor) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Distributor </h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.distributor?.map((item, index) => (
											<span key={item?.tentUserUuid}>
												{`${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || ''}`}
												{_.isEqual(appliedFiltersForprint?.distributor.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Commission value */}
						{isDistributor && !_.isEmpty(appliedFiltersForprint?.commissionTier) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Commission value</h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.commissionTier?.map((item, index) => (
											<span key={item?.commissionUuid}>
												{item?.commissionName || ''}
												{_.isEqual(appliedFiltersForprint?.commissionTier.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Products  */}
						{isDistributor && !_.isEmpty(appliedFiltersForprint?.selectedProducts) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Products</h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.selectedProducts?.map((item, index) => (
											<span key={item?.uuid}>
												{item?.name || ''}
												{_.isEqual(appliedFiltersForprint?.selectedProducts.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* selectedPractice  */}
						{isDistributor && !_.isEmpty(appliedFiltersForprint?.selectedPractice) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Practice</h5>
									<div className={classes.filterContent}>
										:
										{appliedFiltersForprint?.selectedPractice?.map((item, index) => (
											<span key={item?.masterTenantUuid}>
												{item?.practiceName || ''}
												{_.isEqual(appliedFiltersForprint?.selectedPractice.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Membership  */}
						{isMembership && !_.isEmpty(selectedFilters?.membership) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Membership</h5>
									<div className={classes.filterContent}>
										:
										{selectedFilters?.membership?.map((item, index) => (
											<span key={item?.tentMembershipUuid}>
												{item?.membershipName || ''}
												{_.isEqual(selectedFilters?.membership.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}

						{/* Membership status  */}
						{isMembership && !_.isEmpty(selectedFilters?.membershipStatus) && (
							<Grid item xs={6}>
								<div className={classes.filterItem}>
									<h5>Membership status</h5>
									<div className={classes.filterContent}>
										:
										{selectedFilters?.membershipStatus?.map((item, index) => (
											<span key={item?.label}>
												{item?.label || ''}
												{_.isEqual(selectedFilters?.membershipStatus.length, index + 1) ? '.' : ', '}
											</span>
										))}
									</div>
								</div>
							</Grid>
						)}
					</Grid>
				</div>
			</div>

			{/* Statistics Cards */}
			<div className={classes.statisticsContainer}>
				{isNewPatients && (
					<div className={classes.card}>
						<h5>Total Patients</h5>
						<p>{numberToIndianvalue(pageData?.totalValue || 0)}</p>
					</div>
				)}

				{isAppointments && (
					<div className={classes.card}>
						<h5>Total Appointments</h5>
						<p>{numberToIndianvalue(pageData?.totalValue || 0)}</p>
					</div>
				)}

				{isInvoicedDetail && (
					<>
						<div className={classes.card}>
							<h5>Cost ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.totalCost || 0, currencyType)}</p>
						</div>
						<div className={classes.card}>
							<h5>Discount ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.totalDiscount || 0, currencyType)}</p>
						</div>
						<div className={classes.card}>
							<h5>Tax ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.totalTax || 0, currencyType)}</p>
						</div>
						<div className={classes.card}>
							<h5>Amount invoiced ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.totalInvoiceAmount || 0, currencyType)}</p>
						</div>
						<div className={classes.card}>
							<h5>Amount received ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.totalAmountPaid || 0, currencyType)}</p>
						</div>
						<div className={classes.card}>
							<h5>Amount Due ({currency})</h5>
							<p>{currencyCode(pageData?.totalValue?.amtDue || 0, currencyType)}</p>
						</div>
					</>
				)}

				{isDistributor && (
					<>
						<div className={classes.card}>
							<h5>Total no. sales </h5>
							<p> {pageData?.totalValue?.TotalSales ? numberToIndianvalue(pageData?.totalValue?.TotalSales || 0, currencyType) : 0}</p>
						</div>
						<div className={classes.card}>
							<h5>Total price ({currency})</h5>
							<p>
								{pageData?.totalValue?.TotalAmount
									? typeof pageData?.totalValue?.TotalAmount === 'number'
										? currencyCode(pageData?.totalValue?.TotalAmount || 0, currencyType)
										: 0
									: 0}
							</p>
						</div>
						<div className={classes.card}>
							<h5>Commission value ({currency})</h5>
							<p>
								{pageData?.totalValue?.CommissionAmount
									? typeof pageData?.totalValue?.CommissionAmount === 'number'
										? currencyCode(pageData?.totalValue?.CommissionAmount || 0, currencyType)
										: 0
									: 0}
							</p>
						</div>
						<div className={classes.card}>
							<h5>RO commission value ({currency})</h5>
							<p>
								{pageData?.totalValue?.RoCommissionValue
									? typeof pageData?.totalValue?.RoCommissionValue === 'number'
										? currencyCode(pageData?.totalValue?.RoCommissionValue || 0, currencyType)
										: 0
									: 0}
							</p>
						</div>
						<div className={classes.card}>
							<h5>Final price ({currency})</h5>
							<p>
								{pageData?.totalValue?.FinalPrice
									? typeof pageData?.totalValue?.FinalPrice === 'number'
										? currencyCode(pageData?.totalValue?.FinalPrice || 0, currencyType)
										: 0
									: 0}
							</p>
						</div>
					</>
				)}
			</div>

			{/* Table */}
			<DataTable
				printRow={printRow}
				isCare={isCare}
				isNewPatients={isNewPatients}
				isAppointments={isAppointments}
				isInvoicedDetail={isInvoicedDetail}
				isDistributor={isDistributor}
				isMembership={isMembership}
			/>
		</div>
	)
}

export default FuncComponent
