import FuseScrollbars from '@fuse/core/FuseScrollbars'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import clsx from 'clsx'
import { React, memo, useEffect } from 'react'
import { ListItemText, Divider, List, ListItemButton, ListItemIcon, Collapse, ButtonBase, Avatar, Box, Typography, Stack } from '@mui/material'
import { useState } from 'react'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { getImgUrl } from 'utils'
import { updateCurrentIndex } from 'services/sidemenu/action'
import secureLocalStorage from 'react-secure-storage'

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'&:hover .scrollable': {
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'block',
		},
	},
}))

const ScrollDiv = styled('div')(({ theme }) => ({
	overflow: 'scroll',
	overflowX: 'hidden',
	'&::-webkit-scrollbar': {
		display: 'none',
	},
	' .scrollable': {
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'block',
		},
	},
}))

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
	overscrollBehavior: 'contain',
	overflowX: 'hidden',
	overflowY: 'auto',
	'&::-webkit-scrollbar': {
		display: 'none',
	},
	scrollbarWidth: 'none', // For Firefox
	WebkitOverflowScrolling: 'touch',
	background: 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 10px',
	backgroundAttachment: 'local, scroll',
}))

function NavbarStyle2Content(props) {
	let location = useLocation()
	const pathName = location.pathname.split('/').pop()
	const mainmenu = useSelector((state) => state?.dynamicMenu?.list || [])
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const currentIndex = useSelector((state) => state?.SideMenuReducer?.currentIndex)
	const { userName, BearerToken } = state
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const { groupName, docDriveUuid } = mainmenuState
	const isCare = _.isEqual(groupName, 'health care')
	const careEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Care.svg`
	const NonCareEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Non_Care.svg`
	const [open, setOpen] = useState(false)
	const [hovered, setHovered] = useState(false)

	useEffect(() => {
		secureLocalStorage.setItem('currentIndex', currentIndex)
	}, [currentIndex])
	const handleClick = (hasSubMenu, url, index) => {
		// setCurrentIndex(index)
		dispatch(updateCurrentIndex(index))
		if (hasSubMenu) {
			setOpen(!open)
		}
	}

	useEffect(() => {
		switch (window?.location?.pathname) {
			case '/dashboard':
				dispatch(updateCurrentIndex('dashboard'))
				break
			case '/quicksale':
				dispatch(updateCurrentIndex('quicksale'))
				break
			case '/report':
				dispatch(updateCurrentIndex('report'))
				break
			case '/calendar':
				dispatch(updateCurrentIndex('calendar'))
				break
			case '/communication/whatsapp':
				dispatch(updateCurrentIndex('communication'))
				break
			case '/leads':
				dispatch(updateCurrentIndex('leads'))
				break
			case '/chats':
				dispatch(updateCurrentIndex('chats'))
				break
			case '/inventory':
				dispatch(updateCurrentIndex('inventory'))
				break
			case '/expenses':
				dispatch(updateCurrentIndex('expenses'))
				break
			case '/events':
				dispatch(updateCurrentIndex('events'))
				break
			case '/products':
				dispatch(updateCurrentIndex('products'))
				break
			case '/setting':
				dispatch(updateCurrentIndex('setting'))
				break
			case '/profile':
				dispatch(updateCurrentIndex('profile'))
				break
			case '/feedback':
				dispatch(updateCurrentIndex('feedback'))
				break
			case '/attendance':
				dispatch(updateCurrentIndex('attendance'))
				break
			default:
				// Handle default case if needed
				break
		}
	}, [dispatch])
	// Temporarily removed for selected color retain, If any issue occurs please uncomment.
	// useEffect(() => {
	// 	if (pathName) {
	// 		setCurrentIndex(location.pathname.split('/').pop())
	// 	}
	// }, [location.pathname, pathName])

	return (
		<Root
			className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<AppBar
				style={{ backgroundColor: '#FFFFFF' }}
				position='static'
				className='flex flex-row items-center justify-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0'
			>
				{/* <div className=''><Logo /></div> */}
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						style={{ height: 50, width: 50, objectFit: 'contain', borderRadius: 4 }}
						src={docDriveUuid !== null ? getImgUrl(docDriveUuid, BearerToken) : isCare ? careEmptyIcon : NonCareEmptyIcon}
						alt={userName}
					/>
				</div>
				{/* {(props.foldedandopened || !props.folded) && <NavbarToggleButton />} */}
			</AppBar>

			{/* <StyledContent option={{ suppressScrollX: true, wheelPropagation: false }}> */}

			<ScrollDiv className={hovered ? 'scrollable' : ''}>
				{!_.isEmpty(mainmenu?.topMenu) &&
					_.map(mainmenu?.topMenu, (item) => (
						<>
							{item?.display && item?.menuOrder !== 'Right' && (
								<List
									key={item?.mastMenuOrder}
									sx={{ width: '100%', maxWidth: 360, bgcolor: 'primary' }}
									component='nav'
									aria-labelledby='nested-list-subheader'
								>
									<ListItemButton
										// disabled={item?.display}
										// disableRipple
										style={{
											flexDirection: 'column',
											paddingInline: 0,
											paddingBlock: 4,
											borderRadius: item?.mastMenuUrl === currentIndex ? '8px' : 8,
											margin: item?.mastMenuUrl === currentIndex ? '4px' : 4,
											backgroundColor: item?.mastMenuUrl === currentIndex ? item?.colourCode2 : 'inherit',
										}}
										selected={item?.mastMenuUrl === currentIndex}
										component={_.isEmpty(item.subMenu) ? Link : ButtonBase}
										to={_.isEqual(item.mastMenuUrl, 'communication') ? `/${item.mastMenuUrl}/whatsapp` : `/${item.mastMenuUrl}`}
										onClick={() => handleClick(!_.isEmpty(item.subMenu), item.mastMenuUrl, item?.mastMenuUrl)}
									>
										<ListItemIcon className='justify-center'>
											<img src={item.mastMenuDefaulticon} alt='icon' />
											{/* <Icon sx={{ color: item?.mastMenuUrl === currentIndex ? '#0062DD' : '' }}>{item.mastMenuDefaulticon}</Icon> */}
										</ListItemIcon>
										<ListItemText style={{ textAlign: 'center', color: item?.colourCode1, fontWeight: 600 }}>{item?.mastMenuName}</ListItemText>
										{_.isEmpty(item.subMenu) ? null : item.subMenu && open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									</ListItemButton>
									{item?.subMenu?.map((data) => (
										<Collapse in={open}>
											{item?.display && item?.menuOrder !== 'Right' && (
												<List component='div'>
													<ListItemButton
														// disabled={item?.display}
														style={{
															flexDirection: 'column',
															paddingInline: 0,
															paddingBlock: 4,
															borderRadius: data?.mastMenuUrl === currentIndex ? '8px' : 8,
															margin: data?.mastMenuUrl === currentIndex ? '4px' : 4,
															backgroundColor: data?.mastMenuUrl === currentIndex ? data?.colourCode2 : 'inherit',
														}}
														sx={{ pl: 4 }}
														selected={data?.mastMenuUrl === currentIndex}
														component={Link}
														to={`/${data.mastMenuUrl}`}
														onClick={() => handleClick(!_.isEmpty(data.subMenu), data.mastMenuUrl, data?.mastMenuUrl)}
													>
														<ListItemIcon className='justify-center'>
															<img src={data.mastMenuDefaulticon} alt='icon' />
															{/* <Icon style={{ color: data?.mastMenuUrl === currentIndex ? '#0062DD' : '' }}>{data.mastMenuDefaulticon}</Icon> */}
														</ListItemIcon>
														<ListItemText style={{ textAlign: 'center', color: data?.colourCode1 }}>{data?.mastMenuName}</ListItemText>
													</ListItemButton>
												</List>
											)}
										</Collapse>
									))}
								</List>
							)}
						</>
					))}
				{!_.isEmpty(mainmenu.bottomMenu) &&
					_.map(mainmenu.bottomMenu, (item) => (
						<>
							{item?.display && item?.menuOrder !== 'Right' && (
								<List
									key={item?.mastMenuOrder}
									sx={{ width: '100%', maxWidth: 360, bgcolor: 'primary' }}
									component='nav'
									aria-labelledby='nested-list-subheader'
								>
									<ListItemButton
										// disabled={item?.display}
										selected={item?.mastMenuUrl === currentIndex}
										style={{
											flexDirection: 'column',
											paddingInline: 0,
											paddingBlock: 4,
											borderRadius: item?.mastMenuUrl === currentIndex ? '8px' : 8,
											margin: item?.mastMenuUrl === currentIndex ? '4px' : 4,
											backgroundColor: item?.mastMenuUrl === currentIndex ? item?.colourCode2 : 'inherit',
										}}
										component={_.isEmpty(item.subMenu) ? Link : ButtonBase}
										to={`/${item.mastMenuUrl}`}
										onClick={() => handleClick(!_.isEmpty(item.subMenu), item.mastMenuUrl, item?.mastMenuUrl)}
									>
										<ListItemIcon className='justify-center'>
											<img src={item.mastMenuDefaulticon} alt='icon' />
											{/* <Icon>{item.mastMenuDefaulticon}</Icon> */}
										</ListItemIcon>
										<ListItemText style={{ color: item?.colourCode1 }}>{item?.mastMenuName}</ListItemText>

										{_.isEmpty(item.subMenu) ? null : item.subMenu && open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
									</ListItemButton>
									{item?.subMenu?.map((data) => (
										<Collapse in={open}>
											{item?.display && item?.menuOrder !== 'Right' && (
												<List component='div'>
													<ListItemButton
														// disabled={item?.display}
														sx={{ pl: 4 }}
														style={{
															flexDirection: 'column',
															paddingInline: 0,
															paddingBlock: 4,
															borderRadius: data?.mastMenuUrl === currentIndex ? '8px' : 8,
															margin: data?.mastMenuUrl === currentIndex ? '4px' : 4,
															backgroundColor: data?.mastMenuUrl === currentIndex ? data?.colourCode2 : 'inherit',
														}}
														selected={data?.mastMenuUrl === currentIndex}
														component={Link}
														to={`/${data.mastMenuUrl}`}
														onClick={() => handleClick(!_.isEmpty(data.subMenu), data.mastMenuUrl, data?.mastMenuUrl)}
													>
														<ListItemIcon className='justify-center'>
															<img src={data.mastMenuDefaulticon} alt='icon' />
															{/* <Icon>{data.mastMenuDefaulticon}</Icon> */}
														</ListItemIcon>
														<ListItemText style={{ textAlign: 'center', color: data?.colourCode1 }}>{data?.mastMenuName}</ListItemText>
													</ListItemButton>
												</List>
											)}
										</Collapse>
									))}
								</List>
							)}
						</>
					))}
				<Divider />

				<Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<Typography variant='subtitle1' textAlign={'center'} color={'#727272'} fontSize={'12px'}>
						Powered by
					</Typography>
					<img
						style={{ width: 60, height: 60 }}
						src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Main_menu_icons/LYFnGO_logo.svg`}
						alt='lyfngo'
					/>
				</Stack>
			</ScrollDiv>
			{/* </StyledContent> */}
		</Root>
	)
}

export default memo(NavbarStyle2Content)
